// project-imports
// import applications from './applications';
import widget from "./widget";
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import support from './support';
// import pages from './pages';

// types
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS ||============================== //
const menuItems: { items: NavItemType[] } = {
  items: [widget],
  // items: [widget, applications, formsTables, chartsMap, pages, support]
};

export default menuItems;
