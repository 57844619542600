// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="48"
      viewBox="0 0 2048 2048"
      enable-background="new 0 0 2048 2048"
    >
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M1204.000000,2049.000000 
	C802.666687,2049.000000 401.833344,2049.000000 1.000000,2049.000000 
	C1.000000,1366.333374 1.000000,683.666687 1.000000,1.000000 
	C683.666687,1.000000 1366.333374,1.000000 2049.000000,1.000000 
	C2049.000000,683.666687 2049.000000,1366.333374 2049.000000,2049.000000 
	C1767.500000,2049.000000 1486.000000,2049.000000 1204.000000,2049.000000 
M265.240051,1133.807739 
	C265.389832,1139.972656 265.684174,1146.138306 265.652954,1152.302246 
	C265.613739,1160.032471 265.282196,1167.760620 265.126434,1175.490723 
	C265.037292,1179.914795 264.961975,1184.342285 265.031006,1188.765869 
	C265.140778,1195.800659 265.528198,1202.833740 265.517426,1209.867065 
	C265.505920,1217.377441 265.062866,1224.886841 265.029602,1232.397583 
	C265.017517,1235.127319 265.945862,1237.862793 265.921906,1240.590942 
	C265.853851,1248.352173 265.354584,1256.110474 265.335602,1263.870728 
	C265.320221,1270.151855 265.999023,1276.436401 265.936798,1282.715210 
	C265.825928,1293.904907 265.300537,1305.089844 265.113190,1316.279663 
	C264.953339,1325.826538 264.938202,1335.377930 265.017242,1344.926025 
	C265.103394,1355.328491 265.520966,1365.729370 265.519531,1376.130981 
	C265.518036,1386.871338 265.000977,1397.612793 265.055847,1408.351929 
	C265.102478,1417.479004 265.803101,1426.601562 265.921875,1435.729858 
	C266.062866,1446.565430 265.853607,1457.404907 265.864319,1468.242676 
	C265.876526,1480.623779 265.732300,1493.011475 266.091278,1505.383789 
	C266.381653,1515.391968 266.623688,1525.486450 268.115479,1535.356934 
	C270.954956,1554.144165 274.207764,1572.928833 281.368286,1590.694946 
	C287.062378,1604.822754 292.652191,1619.019531 299.102661,1632.804810 
	C302.800018,1640.706421 307.784637,1648.082642 312.790527,1655.277344 
	C320.697021,1666.640991 328.555328,1678.120361 337.471649,1688.674194 
	C345.403320,1698.062500 354.320862,1706.743286 363.575562,1714.855103 
	C373.945770,1723.944824 384.532166,1733.098633 396.205170,1740.314575 
	C411.650269,1749.862549 428.052155,1757.946655 444.416626,1765.889648 
	C453.110565,1770.109619 462.427399,1773.306519 471.761932,1775.881714 
	C483.748016,1779.188232 496.100067,1781.143311 508.174896,1784.159180 
	C523.424438,1787.967773 539.009338,1788.528564 554.514587,1788.831665 
	C582.835571,1789.385376 611.174988,1789.003174 639.506714,1789.002563 
	C866.826416,1788.997559 1094.146118,1789.032837 1321.465820,1788.956177 
	C1381.979126,1788.935791 1442.492554,1788.584473 1503.005859,1788.353882 
	C1522.831299,1788.278320 1542.314697,1785.174072 1561.487427,1780.531494 
	C1586.805298,1774.400757 1611.236450,1765.602661 1633.585571,1752.003540 
	C1648.248047,1743.081543 1663.015869,1734.091187 1676.503662,1723.553467 
	C1688.145142,1714.458374 1698.664185,1703.688232 1708.544678,1692.643433 
	C1718.804688,1681.174072 1728.491211,1669.011230 1737.015991,1656.211060 
	C1750.951050,1635.286987 1762.052979,1612.698486 1769.789551,1588.755981 
	C1778.604126,1561.477295 1783.850586,1533.438110 1783.983887,1504.618408 
	C1784.025146,1495.677368 1783.922119,1486.734375 1784.072632,1477.795410 
	C1784.297974,1464.419312 1784.868286,1451.047119 1784.955444,1437.671021 
	C1785.031860,1425.951050 1784.582520,1414.228638 1784.480103,1402.506348 
	C1784.375977,1390.599731 1784.328979,1378.691528 1784.416748,1366.785156 
	C1784.474609,1358.946533 1784.977051,1351.110229 1784.980469,1343.272705 
	C1784.983398,1336.445923 1784.440796,1329.620361 1784.362793,1322.791626 
	C1784.238037,1311.876221 1784.204346,1300.958008 1784.307495,1290.042480 
	C1784.410645,1279.122437 1784.890991,1268.204590 1784.904053,1257.285400 
	C1784.915527,1247.703491 1784.409058,1238.121948 1784.311401,1228.538574 
	C1784.178589,1215.512329 1784.224731,1202.484375 1784.179443,1189.457153 
	C1784.134155,1176.452026 1783.965210,1163.446289 1784.046631,1150.442017 
	C1784.117432,1139.129028 1784.615112,1127.818237 1784.640137,1116.505737 
	C1784.662598,1106.343628 1784.082275,1096.179199 1784.154541,1086.018555 
	C1784.270142,1069.765503 1784.829956,1053.515991 1784.967896,1037.262817 
	C1785.085938,1023.361938 1784.844482,1009.458313 1784.819336,995.555786 
	C1784.799805,984.811218 1784.878174,974.066589 1784.897217,963.321960 
	C1784.925415,947.377991 1785.003296,931.433777 1784.941040,915.490112 
	C1784.884399,901.004578 1784.677490,886.519592 1784.521240,872.034424 
	C1784.397339,860.551636 1784.089844,849.068115 1784.162964,837.586792 
	C1784.223633,828.065491 1784.894287,818.548401 1784.974243,809.026733 
	C1785.050171,799.989014 1784.619995,790.948181 1784.580200,781.908020 
	C1784.546509,774.241394 1784.898438,766.572083 1784.811035,758.906982 
	C1784.726929,751.520752 1784.147095,744.140259 1784.058105,736.753967 
	C1783.932861,726.351990 1784.034790,715.946838 1784.085327,705.543335 
	C1784.137939,694.698242 1784.199219,683.852783 1784.341064,673.008667 
	C1784.476807,662.631714 1784.870972,652.256287 1784.879517,641.879944 
	C1784.890503,628.762451 1784.529053,615.644714 1784.511719,602.526855 
	C1784.498291,592.326721 1785.071655,582.118713 1784.825684,571.927734 
	C1784.562012,561.017090 1783.905518,550.099060 1782.941772,539.226685 
	C1781.892578,527.388916 1781.492676,515.302979 1778.659546,503.857513 
	C1774.143799,485.615173 1768.845215,467.422424 1761.992188,449.948181 
	C1756.446533,435.807770 1748.746338,422.379852 1740.853149,409.330872 
	C1733.337891,396.906616 1724.981445,384.852875 1715.787354,373.625000 
	C1708.036743,364.159790 1698.950684,355.661926 1689.735229,347.555206 
	C1678.707520,337.854279 1667.215820,328.612518 1655.376587,319.919006 
	C1639.958618,308.597626 1622.756958,300.271240 1605.213257,292.799896 
	C1585.284302,284.312836 1564.688721,277.841064 1543.134155,275.037567 
	C1528.014771,273.070984 1512.855591,271.200806 1497.654663,270.141968 
	C1486.635010,269.374359 1475.518066,270.013855 1464.444458,270.013428 
	C1380.361450,270.010223 1296.278442,270.001129 1212.195435,269.999390 
	C1126.058350,269.997589 1039.921265,270.004425 953.784241,270.003265 
	C859.949646,270.002014 766.114929,269.888947 672.280579,270.039001 
	C628.047485,270.109741 583.803528,270.206909 539.588806,271.287018 
	C524.371033,271.658783 509.131317,274.520355 494.082031,277.242126 
	C483.594879,279.138824 473.199036,282.127502 463.144440,285.702759 
	C444.182892,292.445160 425.870361,300.657715 408.491730,311.018677 
	C388.581726,322.888763 370.274963,336.814545 353.799774,352.964539 
	C344.136353,362.437195 335.818573,373.288361 326.960541,383.575439 
	C315.820496,396.512573 306.725128,410.931366 299.245789,426.124878 
	C291.805908,441.238251 285.286530,456.924896 279.851898,472.867523 
	C275.424591,485.854980 272.748535,499.505646 270.144562,513.025635 
	C268.341583,522.386902 267.753235,532.009033 267.039825,541.545776 
	C266.280426,551.697510 265.869843,561.881958 265.585510,572.059937 
	C265.418549,578.036926 265.866882,584.028564 265.906067,590.014526 
	C265.948364,596.470886 265.788300,602.928284 265.801788,609.385071 
	C265.826324,621.146423 265.996033,632.908020 265.951630,644.668945 
	C265.908722,656.028320 265.557404,667.387085 265.554565,678.746094 
	C265.552002,689.044800 266.033386,699.345459 265.941864,709.641968 
	C265.839661,721.145081 265.184784,732.642822 265.056641,744.146118 
	C264.979584,751.062500 265.603546,757.984314 265.636047,764.904968 
	C265.684753,775.278137 265.384766,785.652832 265.426666,796.026123 
	C265.478241,808.790222 265.865753,821.553589 265.853729,834.317017 
	C265.842346,846.399780 265.447815,858.481750 265.322174,870.565063 
	C265.204224,881.907349 265.200745,893.251038 265.179077,904.594177 
	C265.164642,912.152039 265.116638,919.711609 265.248657,927.267517 
	C265.381439,934.866089 265.917908,942.461243 265.912994,950.057617 
	C265.907501,958.540283 265.398010,967.021484 265.285797,975.505615 
	C265.203644,981.713257 265.474304,987.924805 265.470551,994.134521 
	C265.465149,1003.043640 265.279144,1011.953186 265.319672,1020.861816 
	C265.338104,1024.912720 265.868530,1028.961182 265.892883,1033.012451 
	C265.929291,1039.065552 265.638550,1045.120239 265.641113,1051.174194 
	C265.645020,1060.278076 265.844971,1069.381836 265.847473,1078.485718 
	C265.850830,1090.752563 265.805298,1103.020264 265.661530,1115.286133 
	C265.591736,1121.242310 265.250977,1127.195435 265.240051,1133.807739 
z"
      />
      <path
        fill="#E49F1B"
        opacity="1.000000"
        stroke="none"
        d="
M265.136475,1133.478760 
	C265.250977,1127.195435 265.591736,1121.242310 265.661530,1115.286133 
	C265.805298,1103.020264 265.850830,1090.752563 265.847473,1078.485718 
	C265.844971,1069.381836 265.645020,1060.278076 265.641113,1051.174194 
	C265.638550,1045.120239 265.929291,1039.065552 265.892883,1033.012451 
	C265.868530,1028.961182 265.338104,1024.912720 265.319672,1020.861816 
	C265.279144,1011.953186 265.465149,1003.043640 265.470551,994.134521 
	C265.474304,987.924805 265.203644,981.713257 265.285797,975.505615 
	C265.398010,967.021484 265.907501,958.540283 265.912994,950.057617 
	C265.917908,942.461243 265.381439,934.866089 265.248657,927.267517 
	C265.116638,919.711609 265.164642,912.152039 265.179077,904.594177 
	C265.200745,893.251038 265.204224,881.907349 265.322174,870.565063 
	C265.447815,858.481750 265.842346,846.399780 265.853729,834.317017 
	C265.865753,821.553589 265.478241,808.790222 265.426666,796.026123 
	C265.384766,785.652832 265.684753,775.278137 265.636047,764.904968 
	C265.603546,757.984314 264.979584,751.062500 265.056641,744.146118 
	C265.184784,732.642822 265.839661,721.145081 265.941864,709.641968 
	C266.033386,699.345459 265.552002,689.044800 265.554565,678.746094 
	C265.557404,667.387085 265.908722,656.028320 265.951630,644.668945 
	C265.996033,632.908020 265.826324,621.146423 265.801788,609.385071 
	C265.788300,602.928284 265.948364,596.470886 265.906067,590.014526 
	C265.866882,584.028564 265.418549,578.036926 265.585510,572.059937 
	C265.869843,561.881958 266.280426,551.697510 267.039825,541.545776 
	C267.753235,532.009033 268.341583,522.386902 270.144562,513.025635 
	C272.748535,499.505646 275.424591,485.854980 279.851898,472.867523 
	C285.286530,456.924896 291.805908,441.238251 299.245789,426.124878 
	C306.725128,410.931366 315.820496,396.512573 326.960541,383.575439 
	C335.818573,373.288361 344.136353,362.437195 353.799774,352.964539 
	C370.274963,336.814545 388.581726,322.888763 408.491730,311.018677 
	C425.870361,300.657715 444.182892,292.445160 463.144440,285.702759 
	C473.199036,282.127502 483.594879,279.138824 494.082031,277.242126 
	C509.131317,274.520355 524.371033,271.658783 539.588806,271.287018 
	C583.803528,270.206909 628.047485,270.109741 672.280579,270.039001 
	C766.114929,269.888947 859.949646,270.002014 953.784241,270.003265 
	C1039.921265,270.004425 1126.058350,269.997589 1212.195435,269.999390 
	C1296.278442,270.001129 1380.361450,270.010223 1464.444458,270.013428 
	C1475.518066,270.013855 1486.635010,269.374359 1497.654663,270.141968 
	C1512.855591,271.200806 1528.014771,273.070984 1543.134155,275.037567 
	C1564.688721,277.841064 1585.284302,284.312836 1605.213257,292.799896 
	C1622.756958,300.271240 1639.958618,308.597626 1655.376587,319.919006 
	C1667.215820,328.612518 1678.707520,337.854279 1689.735229,347.555206 
	C1698.950684,355.661926 1708.036743,364.159790 1715.787354,373.625000 
	C1724.981445,384.852875 1733.337891,396.906616 1740.853149,409.330872 
	C1748.746338,422.379852 1756.446533,435.807770 1761.992188,449.948181 
	C1768.845215,467.422424 1774.143799,485.615173 1778.659546,503.857513 
	C1781.492676,515.302979 1781.892578,527.388916 1782.941772,539.226685 
	C1783.905518,550.099060 1784.562012,561.017090 1784.825684,571.927734 
	C1785.071655,582.118713 1784.498291,592.326721 1784.511719,602.526855 
	C1784.529053,615.644714 1784.890503,628.762451 1784.879517,641.879944 
	C1784.870972,652.256287 1784.476807,662.631714 1784.341064,673.008667 
	C1784.199219,683.852783 1784.137939,694.698242 1784.085327,705.543335 
	C1784.034790,715.946838 1783.932861,726.351990 1784.058105,736.753967 
	C1784.147095,744.140259 1784.726929,751.520752 1784.811035,758.906982 
	C1784.898438,766.572083 1784.546509,774.241394 1784.580200,781.908020 
	C1784.619995,790.948181 1785.050171,799.989014 1784.974243,809.026733 
	C1784.894287,818.548401 1784.223633,828.065491 1784.162964,837.586792 
	C1784.089844,849.068115 1784.397339,860.551636 1784.521240,872.034424 
	C1784.677490,886.519592 1784.884399,901.004578 1784.941040,915.490112 
	C1785.003296,931.433777 1784.925415,947.377991 1784.897217,963.321960 
	C1784.878174,974.066589 1784.799805,984.811218 1784.819336,995.555786 
	C1784.844482,1009.458313 1785.085938,1023.361938 1784.967896,1037.262817 
	C1784.829956,1053.515991 1784.270142,1069.765503 1784.154541,1086.018555 
	C1784.082275,1096.179199 1784.662598,1106.343628 1784.640137,1116.505737 
	C1784.615112,1127.818237 1784.117432,1139.129028 1784.046631,1150.442017 
	C1783.965210,1163.446289 1784.134155,1176.452026 1784.179443,1189.457153 
	C1784.224731,1202.484375 1784.178589,1215.512329 1784.311401,1228.538574 
	C1784.409058,1238.121948 1784.915527,1247.703491 1784.904053,1257.285400 
	C1784.890991,1268.204590 1784.410645,1279.122437 1784.307495,1290.042480 
	C1784.204346,1300.958008 1784.238037,1311.876221 1784.362793,1322.791626 
	C1784.440796,1329.620361 1784.983398,1336.445923 1784.980469,1343.272705 
	C1784.977051,1351.110229 1784.474609,1358.946533 1784.416748,1366.785156 
	C1784.328979,1378.691528 1784.375977,1390.599731 1784.480103,1402.506348 
	C1784.582520,1414.228638 1785.031860,1425.951050 1784.955444,1437.671021 
	C1784.868286,1451.047119 1784.297974,1464.419312 1784.072632,1477.795410 
	C1783.922119,1486.734375 1784.025146,1495.677368 1783.983887,1504.618408 
	C1783.850586,1533.438110 1778.604126,1561.477295 1769.789551,1588.755981 
	C1762.052979,1612.698486 1750.951050,1635.286987 1737.015991,1656.211060 
	C1728.491211,1669.011230 1718.804688,1681.174072 1708.544678,1692.643433 
	C1698.664185,1703.688232 1688.145142,1714.458374 1676.503662,1723.553467 
	C1663.015869,1734.091187 1648.248047,1743.081543 1633.585571,1752.003540 
	C1611.236450,1765.602661 1586.805298,1774.400757 1561.487427,1780.531494 
	C1542.314697,1785.174072 1522.831299,1788.278320 1503.005859,1788.353882 
	C1442.492554,1788.584473 1381.979126,1788.935791 1321.465820,1788.956177 
	C1094.146118,1789.032837 866.826416,1788.997559 639.506714,1789.002563 
	C611.174988,1789.003174 582.835571,1789.385376 554.514587,1788.831665 
	C539.009338,1788.528564 523.424438,1787.967773 508.174896,1784.159180 
	C496.100067,1781.143311 483.748016,1779.188232 471.761932,1775.881714 
	C462.427399,1773.306519 453.110565,1770.109619 444.416626,1765.889648 
	C428.052155,1757.946655 411.650269,1749.862549 396.205170,1740.314575 
	C384.532166,1733.098633 373.945770,1723.944824 363.575562,1714.855103 
	C354.320862,1706.743286 345.403320,1698.062500 337.471649,1688.674194 
	C328.555328,1678.120361 320.697021,1666.640991 312.790527,1655.277344 
	C307.784637,1648.082642 302.800018,1640.706421 299.102661,1632.804810 
	C292.652191,1619.019531 287.062378,1604.822754 281.368286,1590.694946 
	C274.207764,1572.928833 270.954956,1554.144165 268.115479,1535.356934 
	C266.623688,1525.486450 266.381653,1515.391968 266.091278,1505.383789 
	C265.732300,1493.011475 265.876526,1480.623779 265.864319,1468.242676 
	C265.853607,1457.404907 266.062866,1446.565430 265.921875,1435.729858 
	C265.803101,1426.601562 265.102478,1417.479004 265.055847,1408.351929 
	C265.000977,1397.612793 265.518036,1386.871338 265.519531,1376.130981 
	C265.520966,1365.729370 265.103394,1355.328491 265.017242,1344.926025 
	C264.938202,1335.377930 264.953339,1325.826538 265.113190,1316.279663 
	C265.300537,1305.089844 265.825928,1293.904907 265.936798,1282.715210 
	C265.999023,1276.436401 265.320221,1270.151855 265.335602,1263.870728 
	C265.354584,1256.110474 265.853851,1248.352173 265.921906,1240.590942 
	C265.945862,1237.862793 265.017517,1235.127319 265.029602,1232.397583 
	C265.062866,1224.886841 265.505920,1217.377441 265.517426,1209.867065 
	C265.528198,1202.833740 265.140778,1195.800659 265.031006,1188.765869 
	C264.961975,1184.342285 265.037292,1179.914795 265.126434,1175.490723 
	C265.282196,1167.760620 265.613739,1160.032471 265.652954,1152.302246 
	C265.684174,1146.138306 265.389832,1139.972656 265.136475,1133.478760 
M664.849670,1661.962646 
	C672.298645,1661.638550 679.744934,1661.145996 687.197144,1661.030273 
	C698.026062,1660.862183 708.859070,1660.954224 719.690369,1660.955078 
	C973.322205,1660.976318 1226.953979,1660.973511 1480.585815,1661.088257 
	C1496.306274,1661.095337 1511.895386,1660.499268 1527.341431,1657.360229 
	C1546.326538,1653.502075 1563.821289,1645.806152 1580.193237,1635.817627 
	C1596.163330,1626.074341 1609.991211,1613.363770 1621.421997,1598.688477 
	C1632.699463,1584.209839 1641.313965,1568.044800 1647.461914,1550.434814 
	C1653.462158,1533.247437 1656.333130,1515.672607 1656.722900,1497.779053 
	C1657.381592,1467.535400 1657.002319,1437.268555 1657.005859,1407.011475 
	C1657.015381,1324.120605 1656.995361,1241.229858 1656.990356,1158.338989 
	C1656.988525,1126.607666 1656.997559,1094.876465 1656.998169,1063.145142 
	C1656.999268,1010.192993 1656.994507,957.240845 1656.997803,904.288696 
	C1657.000488,860.886292 1657.017212,817.483887 1657.017334,774.081482 
	C1657.017456,725.009338 1657.113037,675.936829 1656.956909,626.865173 
	C1656.871826,600.118652 1656.499390,573.370239 1655.912964,546.630188 
	C1655.781494,540.639832 1654.561890,534.601807 1653.233521,528.723511 
	C1650.119873,514.945618 1645.603027,501.706177 1639.299194,488.929718 
	C1632.457520,475.063263 1623.886230,462.501923 1613.596680,451.197510 
	C1598.684082,434.813812 1580.882568,422.217957 1560.714355,412.857239 
	C1540.832764,403.629608 1520.025146,398.443726 1498.183228,398.088837 
	C1474.098267,397.697418 1450.002197,397.996704 1425.910889,398.004883 
	C1393.771973,398.015747 1361.633057,398.045227 1329.494141,398.049530 
	C1298.909180,398.053619 1268.324097,398.030518 1237.739136,398.028381 
	C1168.738037,398.023590 1099.736938,398.026398 1030.735840,398.022369 
	C940.424561,398.017120 850.113220,398.005615 759.801941,398.002533 
	C733.217712,398.001617 706.633484,398.029877 680.049255,398.027374 
	C647.622375,398.024323 615.195435,397.981537 582.768555,397.996918 
	C560.845032,398.007324 538.946106,397.345093 517.334778,402.871155 
	C502.023865,406.786163 487.648529,412.779541 474.164185,420.595673 
	C455.344849,431.504211 439.334930,445.783936 426.448334,463.580414 
	C417.996490,475.252441 411.052032,487.535706 405.448486,500.830841 
	C396.793732,521.365662 393.586517,542.847534 393.109955,564.778076 
	C392.586548,588.865173 392.931824,612.972595 393.004456,637.070801 
	C393.108276,671.526917 393.430328,705.982666 393.432465,740.438599 
	C393.434570,774.338074 393.057373,808.237488 393.018127,842.137085 
	C392.945892,904.542969 393.001648,966.949036 392.999939,1029.354980 
	C392.998199,1092.149292 392.970276,1154.943726 392.993591,1217.738037 
	C393.011536,1266.001953 392.857056,1314.267700 393.206970,1362.529419 
	C393.582794,1414.363525 390.705414,1466.254272 395.194824,1518.011841 
	C396.061188,1527.999512 398.372284,1538.062256 401.500427,1547.603882 
	C405.625366,1560.186279 411.399750,1572.072632 418.394867,1583.550049 
	C427.423035,1598.362915 438.608459,1610.931152 451.537994,1622.165649 
	C463.053619,1632.171631 475.929779,1640.033203 489.811920,1646.393311 
	C508.863495,1655.121582 528.892334,1660.316284 549.689819,1660.841797 
	C587.764282,1661.803833 625.866333,1661.670166 664.849670,1661.962646 
z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M664.403320,1661.978027 
	C625.866333,1661.670166 587.764282,1661.803833 549.689819,1660.841797 
	C528.892334,1660.316284 508.863495,1655.121582 489.811920,1646.393311 
	C475.929779,1640.033203 463.053619,1632.171631 451.537994,1622.165649 
	C438.608459,1610.931152 427.423035,1598.362915 418.394867,1583.550049 
	C411.399750,1572.072632 405.625366,1560.186279 401.500427,1547.603882 
	C398.372284,1538.062256 396.061188,1527.999512 395.194824,1518.011841 
	C390.705414,1466.254272 393.582794,1414.363525 393.206970,1362.529419 
	C392.857056,1314.267700 393.011536,1266.001953 392.993591,1217.738037 
	C392.970276,1154.943726 392.998199,1092.149292 392.999939,1029.354980 
	C393.001648,966.949036 392.945892,904.542969 393.018127,842.137085 
	C393.057373,808.237488 393.434570,774.338074 393.432465,740.438599 
	C393.430328,705.982666 393.108276,671.526917 393.004456,637.070801 
	C392.931824,612.972595 392.586548,588.865173 393.109955,564.778076 
	C393.586517,542.847534 396.793732,521.365662 405.448486,500.830841 
	C411.052032,487.535706 417.996490,475.252441 426.448334,463.580414 
	C439.334930,445.783936 455.344849,431.504211 474.164185,420.595673 
	C487.648529,412.779541 502.023865,406.786163 517.334778,402.871155 
	C538.946106,397.345093 560.845032,398.007324 582.768555,397.996918 
	C615.195435,397.981537 647.622375,398.024323 680.049255,398.027374 
	C706.633484,398.029877 733.217712,398.001617 759.801941,398.002533 
	C850.113220,398.005615 940.424561,398.017120 1030.735840,398.022369 
	C1099.736938,398.026398 1168.738037,398.023590 1237.739136,398.028381 
	C1268.324097,398.030518 1298.909180,398.053619 1329.494141,398.049530 
	C1361.633057,398.045227 1393.771973,398.015747 1425.910889,398.004883 
	C1450.002197,397.996704 1474.098267,397.697418 1498.183228,398.088837 
	C1520.025146,398.443726 1540.832764,403.629608 1560.714355,412.857239 
	C1580.882568,422.217957 1598.684082,434.813812 1613.596680,451.197510 
	C1623.886230,462.501923 1632.457520,475.063263 1639.299194,488.929718 
	C1645.603027,501.706177 1650.119873,514.945618 1653.233521,528.723511 
	C1654.561890,534.601807 1655.781494,540.639832 1655.912964,546.630188 
	C1656.499390,573.370239 1656.871826,600.118652 1656.956909,626.865173 
	C1657.113037,675.936829 1657.017456,725.009338 1657.017334,774.081482 
	C1657.017212,817.483887 1657.000488,860.886292 1656.997803,904.288696 
	C1656.994507,957.240845 1656.999268,1010.192993 1656.998169,1063.145142 
	C1656.997559,1094.876465 1656.988525,1126.607666 1656.990356,1158.338989 
	C1656.995361,1241.229858 1657.015381,1324.120605 1657.005859,1407.011475 
	C1657.002319,1437.268555 1657.381592,1467.535400 1656.722900,1497.779053 
	C1656.333130,1515.672607 1653.462158,1533.247437 1647.461914,1550.434814 
	C1641.313965,1568.044800 1632.699463,1584.209839 1621.421997,1598.688477 
	C1609.991211,1613.363770 1596.163330,1626.074341 1580.193237,1635.817627 
	C1563.821289,1645.806152 1546.326538,1653.502075 1527.341431,1657.360229 
	C1511.895386,1660.499268 1496.306274,1661.095337 1480.585815,1661.088257 
	C1226.953979,1660.973511 973.322205,1660.976318 719.690369,1660.955078 
	C708.859070,1660.954224 698.026062,1660.862183 687.197144,1661.030273 
	C679.744934,1661.145996 672.298645,1661.638550 664.403320,1661.978027 
M1355.000977,1502.999756 
	C1355.000977,1502.999756 1355.019653,1503.051392 1355.795288,1503.003418 
	C1357.872192,1503.022339 1359.949219,1503.041260 1362.668335,1503.019653 
	C1363.093384,1503.064209 1363.518555,1503.108765 1364.175171,1503.704224 
	C1364.805786,1503.520630 1365.436523,1503.337036 1366.465210,1503.059326 
	C1366.465210,1503.059326 1366.874023,1503.049683 1367.074097,1503.540649 
	C1367.423096,1503.376221 1367.772217,1503.211792 1368.540771,1503.052246 
	C1368.540771,1503.052246 1368.952881,1503.130249 1369.294189,1503.684326 
	C1370.684937,1503.772095 1372.078979,1503.970337 1373.465942,1503.929199 
	C1381.919434,1503.679199 1390.369995,1503.196045 1398.824219,1503.131714 
	C1410.876221,1503.039917 1422.930908,1503.196777 1434.983643,1503.331787 
	C1446.352783,1503.459229 1457.720581,1503.828003 1469.089233,1503.836426 
	C1476.488892,1503.841919 1483.892090,1503.129639 1491.287842,1503.220215 
	C1499.019531,1503.314819 1499.014893,1503.566772 1499.014038,1496.174194 
	C1499.009399,1452.422607 1499.000244,1408.671021 1499.002319,1364.919434 
	C1499.005615,1294.630981 1499.016357,1224.342651 1499.023926,1154.054199 
	C1499.024170,1152.126587 1499.024048,1150.198975 1499.024048,1147.926636 
	C1488.236328,1147.926636 1478.188354,1147.926636 1467.593384,1147.926636 
	C1467.593384,1256.312744 1467.593384,1364.072754 1467.593384,1472.217407 
	C1433.593628,1472.217407 1399.792603,1472.217407 1365.103516,1472.032227 
	C1291.197754,1472.032227 1217.292114,1472.032227 1142.994141,1472.032227 
	C1142.994141,1481.906372 1142.857422,1491.205200 1143.158813,1500.489868 
	C1143.198608,1501.719482 1145.395020,1503.794434 1146.721069,1503.902344 
	C1151.524170,1504.293457 1156.382935,1504.069092 1161.218750,1503.973633 
	C1169.484741,1503.810303 1177.748535,1503.447754 1186.014648,1503.398682 
	C1192.567261,1503.359741 1199.122192,1503.793823 1205.675537,1503.780396 
	C1212.066772,1503.767212 1218.458496,1503.270508 1224.848022,1503.308350 
	C1237.829224,1503.385132 1250.808716,1503.787354 1263.789795,1503.850586 
	C1274.848633,1503.904541 1285.908813,1503.579834 1296.968262,1503.591919 
	C1307.536011,1503.603516 1318.104736,1503.989258 1328.670898,1503.916626 
	C1336.752197,1503.860840 1344.830566,1503.347046 1353.176636,1503.564331 
	C1353.800171,1503.386230 1354.423584,1503.208130 1355.000977,1502.999756 
M1215.771362,1239.051147 
	C1216.795654,1238.366455 1217.819824,1237.681763 1218.988770,1236.577637 
	C1218.988770,1236.577637 1219.057861,1236.139526 1219.758423,1236.048462 
	C1222.885742,1232.716797 1226.012939,1229.385254 1229.581787,1226.015137 
	C1229.581787,1226.015137 1230.006104,1225.887207 1230.551392,1225.945435 
	C1230.661011,1225.634766 1230.770630,1225.324097 1231.139526,1224.384399 
	C1233.805908,1221.614868 1236.472412,1218.845459 1239.842285,1216.040771 
	C1242.934204,1212.707886 1246.026123,1209.374878 1249.716064,1205.790894 
	C1250.493530,1204.571411 1251.271118,1203.351929 1252.758057,1202.019043 
	C1261.199097,1194.055298 1269.640137,1186.091553 1278.688965,1177.838501 
	C1283.700928,1172.847778 1288.722290,1167.866333 1293.723389,1162.864624 
	C1314.026489,1142.558838 1334.318970,1122.242676 1354.623535,1101.938354 
	C1377.579102,1078.982788 1400.490234,1055.982178 1423.571411,1033.153442 
	C1426.455078,1030.301270 1426.315308,1028.622925 1423.595459,1025.911621 
	C1373.136353,975.610718 1322.744751,925.242065 1272.342529,874.884216 
	C1232.959961,835.536133 1193.566040,796.199463 1154.206787,756.827942 
	C1112.547607,715.155701 1070.900635,673.471252 1029.329712,631.710999 
	C1026.144409,628.511292 1023.963745,628.459534 1020.741821,631.725769 
	C1004.370544,648.322083 987.785583,664.707397 971.314026,681.205139 
	C963.151062,689.381165 955.070740,697.639771 946.319153,706.104370 
	C922.938660,729.490295 899.555725,752.873840 876.178162,776.262756 
	C822.656860,829.809692 769.142578,883.363586 715.616821,936.906067 
	C686.052368,966.479675 656.524536,996.090393 626.818298,1025.520874 
	C623.626038,1028.683472 623.693848,1030.469482 626.782654,1033.471191 
	C638.320068,1044.683960 649.653198,1056.107300 661.037048,1067.477539 
	C675.511353,1081.934448 689.968323,1096.408813 704.418091,1110.890381 
	C716.772583,1123.271973 729.087402,1135.693115 741.454285,1148.062256 
	C757.878784,1164.489746 774.339172,1180.881348 790.779907,1197.292603 
	C803.510559,1210.000366 816.243103,1222.706421 828.960205,1235.427734 
	C852.213013,1258.688477 875.437195,1281.977783 898.707275,1305.221069 
	C915.140137,1321.634888 931.654236,1337.967285 948.084778,1354.383301 
	C960.585571,1366.873047 973.002380,1379.446777 985.474609,1391.965210 
	C997.291321,1403.825684 1009.217407,1415.579224 1020.903503,1427.566772 
	C1023.971497,1430.713989 1026.082520,1430.540161 1029.042725,1427.544067 
	C1043.550171,1412.859863 1058.262939,1398.378540 1072.775513,1383.699219 
	C1086.027222,1370.295166 1099.006470,1356.620850 1112.311523,1343.270752 
	C1122.007935,1333.541260 1132.068726,1324.175781 1141.908569,1314.588013 
	C1147.314087,1309.321045 1152.688110,1304.015625 1157.909668,1298.567749 
	C1162.450928,1293.829346 1166.757935,1288.866577 1171.586670,1283.903687 
	C1171.859253,1283.741089 1171.994263,1283.501221 1172.611206,1282.878906 
	C1185.779297,1270.066040 1198.984253,1257.290649 1212.061401,1244.385742 
	C1213.463745,1243.001831 1214.120728,1240.862305 1215.055786,1239.056519 
	C1215.055786,1239.056519 1215.072754,1239.124146 1215.771362,1239.051147 
M581.963013,613.132202 
	C581.909363,609.483398 581.855713,605.834534 581.959717,601.361572 
	C581.959717,596.626526 581.959717,591.891541 581.959717,587.164307 
	C583.434509,587.087585 584.095825,587.023010 584.757202,587.022949 
	C661.413818,587.010742 738.070374,587.004517 814.726990,586.995117 
	C843.890198,586.991516 873.053345,586.967468 902.216553,586.997192 
	C904.967102,587.000000 907.111084,586.911926 907.040283,583.150757 
	C906.871338,574.168945 906.985779,565.181885 906.985779,555.723389 
	C904.805481,555.470642 902.756348,555.014221 900.710388,555.028076 
	C856.997864,555.323608 813.285950,555.705444 769.573364,555.992737 
	C753.356812,556.099304 737.137817,556.117615 720.921997,555.962708 
	C712.442993,555.881714 703.967590,555.032532 695.492126,555.057739 
	C663.876282,555.151733 632.261353,555.555542 600.645508,555.654114 
	C585.718445,555.700623 570.789124,555.370483 555.863098,555.090454 
	C552.626587,555.029724 550.922668,555.808044 550.963501,559.496033 
	C551.142517,575.656067 551.133362,591.818054 551.048767,608.653931 
	C551.093140,609.109070 551.137573,609.564270 551.039368,610.835449 
	C551.089966,612.211548 551.140503,613.587646 551.039001,615.879639 
	C551.087280,619.591553 551.135498,623.303467 551.073425,627.496582 
	C551.073425,627.496582 551.203186,627.972961 551.063477,628.649048 
	C551.103577,629.105225 551.143677,629.561340 551.038696,630.662231 
	C551.086182,631.097778 551.133728,631.533386 551.037659,632.841064 
	C551.095032,634.899353 551.152405,636.957642 551.072815,639.490723 
	C551.072815,639.490723 551.190674,639.970642 551.054199,640.731140 
	C551.101624,641.495972 551.148987,642.260742 551.068848,643.669128 
	C551.109802,644.101807 551.150696,644.534485 551.038696,645.897644 
	C551.038696,734.168823 551.038696,822.440063 551.038696,910.770996 
	C561.782776,910.770996 571.807007,910.770996 582.737671,910.770996 
	C582.737671,901.575317 582.813354,892.824463 582.721863,884.075378 
	C582.537537,866.444885 582.101196,848.815491 582.071350,831.185242 
	C582.043823,814.882568 582.568176,798.579224 582.566223,782.276184 
	C582.563599,760.738159 582.160217,739.200195 582.165161,717.662170 
	C582.168945,701.016296 582.837219,684.367188 582.653503,667.725708 
	C582.480530,652.057190 581.541260,636.397888 581.048523,620.731079 
	C580.988770,618.830444 581.609375,616.908508 581.957703,614.501648 
	C581.957703,614.501648 581.823242,614.023560 581.963013,613.132202 
M1276.186768,555.054871 
	C1274.267700,555.370239 1272.343994,555.984375 1270.430420,555.953735 
	C1255.332397,555.711487 1240.237549,555.155273 1225.139404,555.061829 
	C1199.812378,554.905029 1174.483398,555.082031 1149.155884,554.960449 
	C1144.614136,554.938660 1142.597168,556.225952 1142.894409,561.185974 
	C1143.302368,567.994019 1143.207886,574.854858 1142.906982,581.674438 
	C1142.722412,585.856628 1144.118042,587.060974 1148.300293,587.055420 
	C1235.955200,586.939270 1323.610352,586.960205 1411.265381,586.954712 
	C1430.055054,586.953552 1448.844727,586.954590 1467.954102,586.954590 
	C1467.954102,695.154053 1467.954102,802.947571 1467.954102,911.031250 
	C1478.779419,911.031250 1488.757202,911.031250 1498.687988,911.031250 
	C1498.860840,909.884338 1498.996338,909.405029 1498.996338,908.925781 
	C1499.004028,861.694214 1498.998657,814.462708 1499.000366,767.231140 
	C1499.001709,729.951111 1499.011841,692.671143 1499.016724,655.391113 
	C1499.020874,623.571228 1498.989258,591.751282 1499.077026,559.931641 
	C1499.086182,556.611328 1498.193237,555.070129 1494.596802,555.104187 
	C1481.059692,555.232178 1467.520630,555.157349 1453.057617,555.012024 
	C1444.379639,555.048035 1435.701660,555.084106 1426.091919,554.984741 
	C1403.060181,554.992798 1380.027588,554.890747 1356.996826,555.051758 
	C1343.917603,555.143188 1330.842651,555.758118 1317.762939,555.940552 
	C1313.505981,556.000000 1309.240112,555.436523 1304.054688,555.012451 
	C1295.048828,555.063538 1286.042969,555.114624 1276.186768,555.054871 
M813.958435,1503.358398 
	C824.005371,1503.255127 834.052246,1503.082520 844.099243,1503.069824 
	C852.683289,1503.058838 861.267578,1503.310913 869.851685,1503.303467 
	C881.002563,1503.293823 892.160400,1502.888428 903.300171,1503.197266 
	C906.919067,1503.297485 907.005066,1501.833984 906.989197,1499.257935 
	C906.944946,1492.094238 906.701843,1484.913574 907.103821,1477.771606 
	C907.371094,1473.023560 905.695557,1471.880981 901.146301,1471.888062 
	C812.181335,1472.027222 723.216064,1472.016724 634.250916,1472.035156 
	C616.959412,1472.038818 599.667908,1472.035767 582.145752,1472.035767 
	C582.145752,1465.791626 582.135803,1459.994263 582.147400,1454.196777 
	C582.207336,1424.122559 582.332520,1394.048462 582.316101,1363.974243 
	C582.304321,1342.465332 582.052673,1320.956665 582.003662,1299.447632 
	C581.946899,1274.566895 581.995178,1249.685913 581.997253,1224.805054 
	C581.999084,1202.777954 581.981445,1180.750732 582.009766,1158.723633 
	C582.023376,1148.122314 582.062500,1148.095581 571.501038,1148.014771 
	C564.756165,1147.963135 558.010620,1148.005005 551.057556,1148.005005 
	C551.057556,1150.063232 551.057678,1151.380493 551.057495,1152.697754 
	C551.049133,1231.998413 551.043030,1311.299072 551.031372,1390.599731 
	C551.026062,1426.580566 551.014771,1462.561523 550.989807,1498.542358 
	C550.987793,1501.440918 550.536011,1504.204834 555.089233,1504.109619 
	C567.115112,1503.857910 579.149780,1504.057373 591.178101,1503.883057 
	C600.659546,1503.745728 610.135986,1503.117554 619.616272,1503.073608 
	C631.071594,1503.020508 642.528809,1503.483521 653.985168,1503.482544 
	C665.265503,1503.481689 676.545654,1503.062866 687.826233,1503.044067 
	C698.567200,1503.026123 709.308472,1503.372559 720.049927,1503.397827 
	C727.508728,1503.415405 734.969849,1502.994873 742.427002,1503.067749 
	C752.230042,1503.163330 762.030151,1503.762939 771.831543,1503.763062 
	C785.658691,1503.763428 799.485901,1503.413208 813.958435,1503.358398 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1171.168579,1284.002930 
	C1166.757935,1288.866577 1162.450928,1293.829346 1157.909668,1298.567749 
	C1152.688110,1304.015625 1147.314087,1309.321045 1141.908569,1314.588013 
	C1132.068726,1324.175781 1122.007935,1333.541260 1112.311523,1343.270752 
	C1099.006470,1356.620850 1086.027222,1370.295166 1072.775513,1383.699219 
	C1058.262939,1398.378540 1043.550171,1412.859863 1029.042725,1427.544067 
	C1026.082520,1430.540161 1023.971497,1430.713989 1020.903503,1427.566772 
	C1009.217407,1415.579224 997.291321,1403.825684 985.474609,1391.965210 
	C973.002380,1379.446777 960.585571,1366.873047 948.084778,1354.383301 
	C931.654236,1337.967285 915.140137,1321.634888 898.707275,1305.221069 
	C875.437195,1281.977783 852.213013,1258.688477 828.960205,1235.427734 
	C816.243103,1222.706421 803.510559,1210.000366 790.779907,1197.292603 
	C774.339172,1180.881348 757.878784,1164.489746 741.454285,1148.062256 
	C729.087402,1135.693115 716.772583,1123.271973 704.418091,1110.890381 
	C689.968323,1096.408813 675.511353,1081.934448 661.037048,1067.477539 
	C649.653198,1056.107300 638.320068,1044.683960 626.782654,1033.471191 
	C623.693848,1030.469482 623.626038,1028.683472 626.818298,1025.520874 
	C656.524536,996.090393 686.052368,966.479675 715.616821,936.906067 
	C769.142578,883.363586 822.656860,829.809692 876.178162,776.262756 
	C899.555725,752.873840 922.938660,729.490295 946.618530,706.349609 
	C942.158325,712.249756 937.646057,718.137878 932.580811,723.504211 
	C925.103027,731.426514 917.075623,738.829834 909.589539,746.744751 
	C905.396912,751.177490 902.125061,756.490112 897.870300,760.852173 
	C894.793518,764.006531 890.639038,766.082336 887.257568,768.980225 
	C885.908325,770.136597 884.430969,772.378113 884.694763,773.817200 
	C884.934326,775.124512 887.325562,776.345276 889.003601,777.010742 
	C891.696716,778.078674 893.201355,779.103394 892.100159,782.504517 
	C891.322937,784.905273 891.715820,787.684753 891.517029,791.689941 
	C886.303589,796.057617 885.708374,797.652283 888.318909,803.305542 
	C888.896851,802.998596 889.559753,802.799744 890.020325,802.379211 
	C898.429260,794.700989 906.935547,787.123779 915.174927,779.266785 
	C927.437622,767.573303 939.414612,755.579102 951.735229,743.948242 
	C954.371094,741.459900 957.956848,739.977844 960.981995,738.349548 
	C863.965759,835.619324 767.072510,932.571777 670.089661,1029.613892 
	C788.435852,1147.910889 906.686340,1266.112183 1024.923340,1384.300049 
	C1046.298096,1362.995117 1067.222900,1342.082031 1088.219482,1321.241211 
	C1095.777222,1313.739380 1103.507324,1306.410889 1111.369019,1299.001587 
	C1111.579346,1298.999878 1112.000000,1298.998047 1112.145020,1299.258545 
	C1111.909668,1300.729126 1111.857666,1302.222168 1111.097168,1303.104248 
	C1105.379517,1309.736084 1099.445435,1316.182251 1093.772583,1322.850952 
	C1092.626709,1324.197998 1091.886719,1326.201782 1091.703369,1327.979370 
	C1091.458008,1330.359375 1092.062744,1332.813477 1092.076660,1335.236938 
	C1092.098267,1338.990356 1093.065186,1341.537354 1097.606445,1340.646606 
	C1098.477051,1340.475708 1099.433105,1340.740234 1101.342285,1340.873047 
	C1097.151001,1349.247192 1087.849365,1353.266724 1086.140503,1362.590698 
	C1088.168213,1362.162720 1089.791016,1361.317993 1091.119751,1360.140991 
	C1093.213623,1358.286621 1095.096069,1356.194946 1097.092285,1354.228516 
	C1101.812500,1349.578613 1106.668091,1345.059082 1111.244019,1340.271851 
	C1116.604614,1334.663818 1121.472290,1328.568115 1127.008423,1323.148438 
	C1136.567383,1313.791016 1146.469971,1304.784302 1156.243286,1295.646362 
	C1160.869263,1291.321167 1163.849243,1284.877808 1171.168579,1284.002930 
z"
      />
      <path
        fill="#E2981A"
        opacity="1.000000"
        stroke="none"
        d="
M961.104980,738.032227 
	C957.956848,739.977844 954.371094,741.459900 951.735229,743.948242 
	C939.414612,755.579102 927.437622,767.573303 915.174927,779.266785 
	C906.935547,787.123779 898.429260,794.700989 890.020325,802.379211 
	C889.559753,802.799744 888.896851,802.998596 888.318909,803.305542 
	C885.708374,797.652283 886.303589,796.057617 891.517029,791.689941 
	C891.715820,787.684753 891.322937,784.905273 892.100159,782.504517 
	C893.201355,779.103394 891.696716,778.078674 889.003601,777.010742 
	C887.325562,776.345276 884.934326,775.124512 884.694763,773.817200 
	C884.430969,772.378113 885.908325,770.136597 887.257568,768.980225 
	C890.639038,766.082336 894.793518,764.006531 897.870300,760.852173 
	C902.125061,756.490112 905.396912,751.177490 909.589539,746.744751 
	C917.075623,738.829834 925.103027,731.426514 932.580811,723.504211 
	C937.646057,718.137878 942.158325,712.249756 946.935425,706.227905 
	C955.070740,697.639771 963.151062,689.381165 971.314026,681.205139 
	C987.785583,664.707397 1004.370544,648.322083 1020.741821,631.725769 
	C1023.963745,628.459534 1026.144409,628.511292 1029.329712,631.710999 
	C1070.900635,673.471252 1112.547607,715.155701 1154.206787,756.827942 
	C1193.566040,796.199463 1232.959961,835.536133 1272.342529,874.884216 
	C1322.744751,925.242065 1373.136353,975.610718 1423.595459,1025.911621 
	C1426.315308,1028.622925 1426.455078,1030.301270 1423.571411,1033.153442 
	C1400.490234,1055.982178 1377.579102,1078.982788 1354.623535,1101.938354 
	C1334.318970,1122.242676 1314.026489,1142.558838 1293.723389,1162.864624 
	C1288.722290,1167.866333 1283.700928,1172.847778 1278.124023,1177.794678 
	C1275.896240,1178.477783 1273.903198,1178.840698 1272.628662,1179.996216 
	C1267.629395,1184.528442 1262.856812,1189.310669 1257.720703,1194.104736 
	C1256.960815,1194.806396 1256.480469,1195.403198 1255.827393,1196.270508 
	C1254.434937,1198.027344 1253.214966,1199.513672 1251.635498,1201.031250 
	C1237.506714,1206.845215 1231.316528,1220.978760 1220.013550,1229.599243 
	C1215.979858,1232.675781 1216.318237,1233.898438 1212.288208,1228.468750 
	C1211.340942,1242.491333 1200.155518,1247.053223 1192.799683,1254.980469 
	C1192.237915,1254.523071 1191.676147,1254.065674 1191.114380,1253.608398 
	C1192.880127,1251.445190 1194.645752,1249.281982 1197.293945,1246.037720 
	C1194.869019,1246.683228 1193.726807,1246.756714 1192.833862,1247.266846 
	C1189.736694,1249.035767 1186.710449,1250.931274 1183.694580,1252.838135 
	C1183.009766,1253.271118 1182.477173,1253.944824 1181.872437,1254.505249 
	C1179.133911,1257.042969 1176.643555,1259.970093 1173.546509,1261.945801 
	C1172.085083,1262.878174 1168.888062,1262.724487 1167.542603,1261.684570 
	C1166.474854,1260.859375 1166.117188,1256.989502 1167.025635,1256.115723 
	C1170.269165,1252.996338 1174.119141,1250.476807 1177.885376,1247.947510 
	C1179.268433,1247.018677 1181.057373,1246.694336 1183.048096,1245.949585 
	C1182.195190,1244.773682 1181.869141,1244.209961 1181.435547,1243.747070 
	C1179.806152,1242.007568 1176.845703,1240.535889 1179.204834,1237.638184 
	C1181.497925,1234.821533 1182.966431,1237.484497 1184.902100,1238.710815 
	C1185.992676,1239.402222 1188.103394,1238.484131 1189.754639,1238.290894 
	C1189.557739,1236.736084 1189.451660,1235.162720 1189.137085,1233.632080 
	C1188.848145,1232.225586 1188.334473,1230.865479 1187.919922,1229.484863 
	C1189.561401,1229.451782 1191.216675,1229.274170 1192.840576,1229.427490 
	C1194.384277,1229.573242 1196.677246,1230.855835 1197.278931,1230.261108 
	C1199.353516,1228.210571 1201.314209,1225.731812 1202.286621,1223.043091 
	C1202.644287,1222.053955 1200.131592,1220.026611 1198.516724,1217.941772 
	C1200.037231,1216.234497 1201.751099,1214.272217 1203.506226,1212.347534 
	C1206.938477,1208.583618 1210.510498,1204.937012 1213.746582,1201.012573 
	C1214.438477,1200.173462 1213.946533,1198.358154 1213.985840,1196.718262 
	C1213.952271,1196.261475 1213.932983,1196.079102 1213.931641,1195.926270 
	C1213.949585,1195.955811 1213.886475,1195.984131 1214.081055,1195.816528 
	C1214.511719,1195.400757 1214.747559,1195.152588 1214.979126,1194.944824 
	C1214.974731,1194.985352 1214.894043,1194.975830 1215.081177,1194.823242 
	C1215.509644,1194.458496 1215.722290,1194.220459 1216.017822,1193.694824 
	C1216.705566,1192.957520 1217.281738,1192.482056 1218.136475,1192.012573 
	C1218.600952,1192.024658 1218.786621,1192.030762 1219.232300,1192.193848 
	C1220.256958,1192.225220 1221.299438,1192.377808 1221.745117,1191.932861 
	C1230.920044,1182.770386 1240.075195,1173.586548 1249.068359,1164.247070 
	C1249.920410,1163.362305 1249.671021,1161.416992 1250.069702,1159.657227 
	C1293.667114,1116.038574 1337.127808,1072.726685 1380.443115,1029.559692 
	C1377.989746,1027.229370 1376.250000,1025.636719 1374.576538,1023.977051 
	C1365.961548,1015.432739 1357.292603,1006.941345 1348.780884,998.295044 
	C1341.053955,990.445923 1333.639771,982.286316 1325.844238,974.507629 
	C1315.213013,963.899231 1304.230591,953.642517 1293.616943,943.016968 
	C1279.474121,928.858398 1265.653198,914.378052 1251.488525,900.241760 
	C1235.445068,884.230286 1219.064331,868.556519 1203.034668,852.531311 
	C1188.353027,837.853699 1174.040283,822.807190 1159.383667,808.104004 
	C1147.978149,796.662231 1136.171143,785.620544 1124.765625,774.178772 
	C1111.763794,761.135681 1099.050659,747.804993 1086.132324,734.677979 
	C1077.539917,725.946899 1068.820435,717.340393 1060.134155,708.702087 
	C1049.078613,697.707458 1038.026245,686.709229 1026.865967,675.821655 
	C1026.159546,675.132568 1023.927734,674.786133 1023.425903,675.292175 
	C1016.260559,682.517944 1009.359497,690.005371 1002.210022,697.247375 
	C996.885986,702.640198 991.295349,707.769836 985.676941,713.183228 
	C985.348450,713.583679 985.183411,713.829102 985.029541,714.052246 
	C985.023743,714.018616 985.091858,714.014465 984.899414,714.182495 
	C984.474854,714.599670 984.242676,714.848816 984.014404,715.065186 
	C984.018250,715.032288 984.079773,715.056580 983.720947,715.073853 
	C981.575745,716.669250 979.789368,718.247375 977.739014,719.946472 
	C977.263123,720.383118 977.051086,720.698853 976.690918,721.140991 
	C976.351746,721.444336 976.175842,721.635193 975.797852,721.914551 
	C975.580566,721.988953 975.124512,722.043762 974.801392,722.181152 
	C974.016235,723.579407 973.554077,724.840271 973.075867,726.070801 
	C973.059814,726.040344 973.124939,726.018250 972.929321,726.182983 
	C972.503723,726.597229 972.273743,726.846741 971.958984,727.320679 
	C971.683289,727.830627 971.417053,727.992249 970.883789,728.195496 
	C970.465881,728.613464 970.239807,728.865723 970.012756,729.066895 
	C970.011780,729.015869 970.113892,729.014160 969.917725,729.183716 
	C969.485596,729.605835 969.249695,729.858398 969.015747,730.062195 
	C969.017700,730.013428 969.115112,730.017822 968.918701,730.174072 
	C968.487061,730.566956 968.251953,730.803589 968.011353,731.015625 
	C968.005920,730.990906 968.050049,731.015564 967.866272,731.170898 
	C967.443787,731.540894 967.220276,731.770447 967.005981,732.007324 
	C967.000000,732.000000 967.014099,732.011292 966.835388,732.173584 
	C966.427002,732.562378 966.215698,732.804749 965.882996,733.388916 
	C964.197205,735.153809 962.651062,736.592957 961.104980,738.032227 
M899.017700,786.080017 
	C900.342102,784.164246 901.666504,782.248535 902.990906,780.332764 
	C897.743591,781.623047 896.982056,783.012634 899.017700,786.080017 
z"
      />
      <path
        fill="#E1971A"
        opacity="1.000000"
        stroke="none"
        d="
M1453.982300,555.158447 
	C1467.520630,555.157349 1481.059692,555.232178 1494.596802,555.104187 
	C1498.193237,555.070129 1499.086182,556.611328 1499.077026,559.931641 
	C1498.989258,591.751282 1499.020874,623.571228 1499.016724,655.391113 
	C1499.011841,692.671143 1499.001709,729.951111 1499.000366,767.231140 
	C1498.998657,814.462708 1499.004028,861.694214 1498.996338,908.925781 
	C1498.996338,909.405029 1498.860840,909.884338 1498.687988,911.031250 
	C1488.757202,911.031250 1478.779419,911.031250 1467.954102,911.031250 
	C1467.954102,802.947571 1467.954102,695.154053 1467.954102,586.954590 
	C1448.844727,586.954590 1430.055054,586.953552 1411.265381,586.954712 
	C1323.610352,586.960205 1235.955200,586.939270 1148.300293,587.055420 
	C1144.118042,587.060974 1142.722412,585.856628 1142.906982,581.674438 
	C1143.207886,574.854858 1143.302368,567.994019 1142.894409,561.185974 
	C1142.597168,556.225952 1144.614136,554.938660 1149.155884,554.960449 
	C1174.483398,555.082031 1199.812378,554.905029 1225.139404,555.061829 
	C1240.237549,555.155273 1255.332397,555.711487 1270.430420,555.953735 
	C1272.343994,555.984375 1274.267700,555.370239 1276.979248,555.287720 
	C1286.840576,555.399719 1295.909546,555.278809 1304.978394,555.157959 
	C1309.240112,555.436523 1313.505981,556.000000 1317.762939,555.940552 
	C1330.842651,555.758118 1343.917603,555.143188 1356.996826,555.051758 
	C1380.027588,554.890747 1403.060181,554.992798 1426.920166,555.243713 
	C1436.493164,555.387939 1445.237671,555.273193 1453.982300,555.158447 
z"
      />
      <path
        fill="#E59D1B"
        opacity="1.000000"
        stroke="none"
        d="
M813.635742,1503.285645 
	C799.485901,1503.413208 785.658691,1503.763428 771.831543,1503.763062 
	C762.030151,1503.762939 752.230042,1503.163330 742.427002,1503.067749 
	C734.969849,1502.994873 727.508728,1503.415405 720.049927,1503.397827 
	C709.308472,1503.372559 698.567200,1503.026123 687.826233,1503.044067 
	C676.545654,1503.062866 665.265503,1503.481689 653.985168,1503.482544 
	C642.528809,1503.483521 631.071594,1503.020508 619.616272,1503.073608 
	C610.135986,1503.117554 600.659546,1503.745728 591.178101,1503.883057 
	C579.149780,1504.057373 567.115112,1503.857910 555.089233,1504.109619 
	C550.536011,1504.204834 550.987793,1501.440918 550.989807,1498.542358 
	C551.014771,1462.561523 551.026062,1426.580566 551.031372,1390.599731 
	C551.043030,1311.299072 551.049133,1231.998413 551.057495,1152.697754 
	C551.057678,1151.380493 551.057556,1150.063232 551.057556,1148.005005 
	C558.010620,1148.005005 564.756165,1147.963135 571.501038,1148.014771 
	C582.062500,1148.095581 582.023376,1148.122314 582.009766,1158.723633 
	C581.981445,1180.750732 581.999084,1202.777954 581.997253,1224.805054 
	C581.995178,1249.685913 581.946899,1274.566895 582.003662,1299.447632 
	C582.052673,1320.956665 582.304321,1342.465332 582.316101,1363.974243 
	C582.332520,1394.048462 582.207336,1424.122559 582.147400,1454.196777 
	C582.135803,1459.994263 582.145752,1465.791626 582.145752,1472.035767 
	C599.667908,1472.035767 616.959412,1472.038818 634.250916,1472.035156 
	C723.216064,1472.016724 812.181335,1472.027222 901.146301,1471.888062 
	C905.695557,1471.880981 907.371094,1473.023560 907.103821,1477.771606 
	C906.701843,1484.913574 906.944946,1492.094238 906.989197,1499.257935 
	C907.005066,1501.833984 906.919067,1503.297485 903.300171,1503.197266 
	C892.160400,1502.888428 881.002563,1503.293823 869.851685,1503.303467 
	C861.267578,1503.310913 852.683289,1503.058838 844.099243,1503.069824 
	C834.052246,1503.082520 824.005371,1503.255127 813.635742,1503.285645 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1365.991699,1472.217407 
	C1399.792603,1472.217407 1433.593628,1472.217407 1467.593384,1472.217407 
	C1467.593384,1364.072754 1467.593384,1256.312744 1467.593384,1147.926636 
	C1478.188354,1147.926636 1488.236328,1147.926636 1499.024048,1147.926636 
	C1499.024048,1150.198975 1499.024170,1152.126587 1499.023926,1154.054199 
	C1499.016357,1224.342651 1499.005615,1294.630981 1499.002319,1364.919434 
	C1499.000244,1408.671021 1499.009399,1452.422607 1499.014038,1496.174194 
	C1499.014893,1503.566772 1499.019531,1503.314819 1491.287842,1503.220215 
	C1483.892090,1503.129639 1476.488892,1503.841919 1469.089233,1503.836426 
	C1457.720581,1503.828003 1446.352783,1503.459229 1434.983643,1503.331787 
	C1422.930908,1503.196777 1410.876221,1503.039917 1398.824219,1503.131714 
	C1390.369995,1503.196045 1381.919434,1503.679199 1373.465942,1503.929199 
	C1372.078979,1503.970337 1370.684937,1503.772095 1369.109863,1503.051758 
	C1369.461792,1500.061890 1369.998047,1497.704590 1370.737061,1494.456543 
	C1369.781494,1494.087646 1367.732544,1493.788818 1366.382446,1492.656738 
	C1364.487183,1491.067139 1363.111084,1488.858398 1361.512817,1486.914551 
	C1363.356445,1486.272461 1365.191772,1485.604858 1367.049438,1485.006470 
	C1367.508057,1484.858765 1368.135986,1485.103516 1368.519653,1484.880981 
	C1369.877930,1484.093018 1371.691895,1483.442383 1372.295532,1482.225098 
	C1372.710449,1481.388184 1371.609985,1479.561646 1370.830078,1478.421387 
	C1369.352783,1476.260742 1367.619019,1474.275513 1365.991699,1472.217407 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M551.192505,607.979370 
	C551.133362,591.818054 551.142517,575.656067 550.963501,559.496033 
	C550.922668,555.808044 552.626587,555.029724 555.863098,555.090454 
	C570.789124,555.370483 585.718445,555.700623 600.645508,555.654114 
	C632.261353,555.555542 663.876282,555.151733 695.492126,555.057739 
	C703.967590,555.032532 712.442993,555.881714 720.921997,555.962708 
	C737.137817,556.117615 753.356812,556.099304 769.573364,555.992737 
	C813.285950,555.705444 856.997864,555.323608 900.710388,555.028076 
	C902.756348,555.014221 904.805481,555.470642 906.985779,555.723389 
	C906.985779,565.181885 906.871338,574.168945 907.040283,583.150757 
	C907.111084,586.911926 904.967102,587.000000 902.216553,586.997192 
	C873.053345,586.967468 843.890198,586.991516 814.726990,586.995117 
	C738.070374,587.004517 661.413818,587.010742 584.757202,587.022949 
	C584.095825,587.023010 583.434509,587.087585 581.959717,587.164307 
	C581.959717,591.891541 581.959717,596.626526 581.489380,601.853760 
	C579.666687,607.457336 578.472656,612.619141 576.869507,617.650696 
	C576.363403,619.239075 574.897339,621.464478 573.628906,621.638794 
	C571.872559,621.880127 569.193970,621.002197 568.131470,619.646484 
	C565.011414,615.665466 562.660522,611.089844 559.660156,607.002869 
	C558.457275,605.364441 556.345276,604.393433 554.647095,603.118652 
	C553.495605,604.738892 552.344055,606.359131 551.192505,607.979370 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M551.120605,608.316650 
	C552.344055,606.359131 553.495605,604.738892 554.647095,603.118652 
	C556.345276,604.393433 558.457275,605.364441 559.660156,607.002869 
	C562.660522,611.089844 565.011414,615.665466 568.131470,619.646484 
	C569.193970,621.002197 571.872559,621.880127 573.628906,621.638794 
	C574.897339,621.464478 576.363403,619.239075 576.869507,617.650696 
	C578.472656,612.619141 579.666687,607.457336 581.410522,602.265808 
	C581.855713,605.834534 581.909363,609.483398 581.831360,613.697571 
	C581.637756,614.543091 581.709412,614.787598 581.914612,614.996399 
	C581.609375,616.908508 580.988770,618.830444 581.048523,620.731079 
	C581.541260,636.397888 582.480530,652.057190 582.653503,667.725708 
	C582.837219,684.367188 582.168945,701.016296 582.165161,717.662170 
	C582.160217,739.200195 582.563599,760.738159 582.566223,782.276184 
	C582.568176,798.579224 582.043823,814.882568 582.071350,831.185242 
	C582.101196,848.815491 582.537537,866.444885 582.721863,884.075378 
	C582.813354,892.824463 582.737671,901.575317 582.737671,910.770996 
	C571.807007,910.770996 561.782776,910.770996 551.038696,910.770996 
	C551.038696,822.440063 551.038696,734.168823 551.231079,645.252441 
	C551.347778,644.080078 551.272034,643.552795 551.196350,643.025574 
	C551.148987,642.260742 551.101624,641.495972 551.192383,640.240662 
	C551.418030,639.487061 551.377808,639.242310 551.209717,639.015930 
	C551.152405,636.957642 551.095032,634.899353 551.225830,632.224609 
	C551.337219,631.078003 551.260498,630.547729 551.183777,630.017517 
	C551.143677,629.561340 551.103577,629.105225 551.199585,628.197754 
	C551.413269,627.479004 551.362610,627.235291 551.183777,627.015381 
	C551.135498,623.303467 551.087280,619.591553 551.270996,615.134521 
	C551.396057,612.932678 551.289001,611.476074 551.182007,610.019409 
	C551.137573,609.564270 551.093140,609.109070 551.120605,608.316650 
z"
      />
      <path
        fill="#E59D1B"
        opacity="1.000000"
        stroke="none"
        d="
M1365.547607,1472.124756 
	C1367.619019,1474.275513 1369.352783,1476.260742 1370.830078,1478.421387 
	C1371.609985,1479.561646 1372.710449,1481.388184 1372.295532,1482.225098 
	C1371.691895,1483.442383 1369.877930,1484.093018 1368.519653,1484.880981 
	C1368.135986,1485.103516 1367.508057,1484.858765 1367.049438,1485.006470 
	C1365.191772,1485.604858 1363.356445,1486.272461 1361.512817,1486.914551 
	C1363.111084,1488.858398 1364.487183,1491.067139 1366.382446,1492.656738 
	C1367.732544,1493.788818 1369.781494,1494.087646 1370.737061,1494.456543 
	C1369.998047,1497.704590 1369.461792,1500.061890 1368.939209,1502.774658 
	C1368.952881,1503.130249 1368.540771,1503.052246 1368.019287,1503.041626 
	C1367.289917,1503.037109 1367.082031,1503.043335 1366.874023,1503.049683 
	C1366.874023,1503.049683 1366.465210,1503.059326 1366.075439,1502.955688 
	C1365.105103,1502.952515 1364.524292,1503.052979 1363.943604,1503.153320 
	C1363.518555,1503.108765 1363.093384,1503.064209 1362.034424,1502.837158 
	C1359.273560,1502.786865 1357.146606,1502.919067 1355.019653,1503.051392 
	C1355.019653,1503.051392 1355.000977,1502.999756 1354.667969,1503.007324 
	C1353.859985,1503.022217 1353.385010,1503.029541 1352.910156,1503.036865 
	C1344.830566,1503.347046 1336.752197,1503.860840 1328.670898,1503.916626 
	C1318.104736,1503.989258 1307.536011,1503.603516 1296.968262,1503.591919 
	C1285.908813,1503.579834 1274.848633,1503.904541 1263.789795,1503.850586 
	C1250.808716,1503.787354 1237.829224,1503.385132 1224.848022,1503.308350 
	C1218.458496,1503.270508 1212.066772,1503.767212 1205.675537,1503.780396 
	C1199.122192,1503.793823 1192.567261,1503.359741 1186.014648,1503.398682 
	C1177.748535,1503.447754 1169.484741,1503.810303 1161.218750,1503.973633 
	C1156.382935,1504.069092 1151.524170,1504.293457 1146.721069,1503.902344 
	C1145.395020,1503.794434 1143.198608,1501.719482 1143.158813,1500.489868 
	C1142.857422,1491.205200 1142.994141,1481.906372 1142.994141,1472.032227 
	C1217.292114,1472.032227 1291.197754,1472.032227 1365.547607,1472.124756 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1214.000000,1196.992676 
	C1213.946533,1198.358154 1214.438477,1200.173462 1213.746582,1201.012573 
	C1210.510498,1204.937012 1206.938477,1208.583618 1203.506226,1212.347534 
	C1201.751099,1214.272217 1200.037231,1216.234497 1198.516724,1217.941772 
	C1200.131592,1220.026611 1202.644287,1222.053955 1202.286621,1223.043091 
	C1201.314209,1225.731812 1199.353516,1228.210571 1197.278931,1230.261108 
	C1196.677246,1230.855835 1194.384277,1229.573242 1192.840576,1229.427490 
	C1191.216675,1229.274170 1189.561401,1229.451782 1187.919922,1229.484863 
	C1188.334473,1230.865479 1188.848145,1232.225586 1189.137085,1233.632080 
	C1189.451660,1235.162720 1189.557739,1236.736084 1189.754639,1238.290894 
	C1188.103394,1238.484131 1185.992676,1239.402222 1184.902100,1238.710815 
	C1182.966431,1237.484497 1181.497925,1234.821533 1179.204834,1237.638184 
	C1176.845703,1240.535889 1179.806152,1242.007568 1181.435547,1243.747070 
	C1181.869141,1244.209961 1182.195190,1244.773682 1183.048096,1245.949585 
	C1181.057373,1246.694336 1179.268433,1247.018677 1177.885376,1247.947510 
	C1174.119141,1250.476807 1170.269165,1252.996338 1167.025635,1256.115723 
	C1166.117188,1256.989502 1166.474854,1260.859375 1167.542603,1261.684570 
	C1168.888062,1262.724487 1172.085083,1262.878174 1173.546509,1261.945801 
	C1176.643555,1259.970093 1179.133911,1257.042969 1181.872437,1254.505249 
	C1182.477173,1253.944824 1183.009766,1253.271118 1183.694580,1252.838135 
	C1186.710449,1250.931274 1189.736694,1249.035767 1192.833862,1247.266846 
	C1193.726807,1246.756714 1194.869019,1246.683228 1197.293945,1246.037720 
	C1194.645752,1249.281982 1192.880127,1251.445190 1191.114380,1253.608398 
	C1191.676147,1254.065674 1192.237915,1254.523071 1192.799683,1254.980469 
	C1200.155518,1247.053223 1211.340942,1242.491333 1212.288208,1228.468750 
	C1216.318237,1233.898438 1215.979858,1232.675781 1220.013550,1229.599243 
	C1231.316528,1220.978760 1237.506714,1206.845215 1251.649414,1201.314331 
	C1252.031494,1201.754883 1252.040039,1201.943604 1252.048584,1202.132324 
	C1251.271118,1203.351929 1250.493530,1204.571411 1249.051514,1205.937012 
	C1244.004517,1208.211304 1239.628174,1210.345093 1239.138794,1216.075928 
	C1236.472412,1218.845459 1233.805908,1221.614868 1230.849609,1224.829346 
	C1230.354614,1225.459961 1230.170044,1225.664185 1230.006104,1225.887207 
	C1230.006104,1225.887207 1229.581787,1226.015137 1229.015869,1226.104004 
	C1224.138428,1228.393433 1220.349609,1231.087646 1219.057861,1236.139526 
	C1219.057861,1236.139526 1218.988770,1236.577637 1218.568848,1236.801270 
	C1217.123535,1237.724731 1216.098145,1238.424438 1215.072754,1239.124146 
	C1215.072754,1239.124146 1215.055786,1239.056519 1214.748291,1239.130249 
	C1212.761841,1240.501587 1210.925659,1241.640381 1209.429199,1243.122314 
	C1199.223267,1253.228516 1189.110718,1263.428711 1178.917725,1273.547729 
	C1176.057739,1276.386963 1171.796753,1278.167480 1171.991577,1283.183838 
	C1171.994263,1283.501221 1171.859253,1283.741089 1171.377686,1283.953369 
	C1163.849243,1284.877808 1160.869263,1291.321167 1156.243286,1295.646362 
	C1146.469971,1304.784302 1136.567383,1313.791016 1127.008423,1323.148438 
	C1121.472290,1328.568115 1116.604614,1334.663818 1111.244019,1340.271851 
	C1106.668091,1345.059082 1101.812500,1349.578613 1097.092285,1354.228516 
	C1095.096069,1356.194946 1093.213623,1358.286621 1091.119751,1360.140991 
	C1089.791016,1361.317993 1088.168213,1362.162720 1086.140503,1362.590698 
	C1087.849365,1353.266724 1097.151001,1349.247192 1101.342285,1340.873047 
	C1099.433105,1340.740234 1098.477051,1340.475708 1097.606445,1340.646606 
	C1093.065186,1341.537354 1092.098267,1338.990356 1092.076660,1335.236938 
	C1092.062744,1332.813477 1091.458008,1330.359375 1091.703369,1327.979370 
	C1091.886719,1326.201782 1092.626709,1324.197998 1093.772583,1322.850952 
	C1099.445435,1316.182251 1105.379517,1309.736084 1111.097168,1303.104248 
	C1111.857666,1302.222168 1111.909668,1300.729126 1112.138550,1298.979980 
	C1111.976196,1298.255493 1111.965210,1298.069946 1112.027344,1297.651245 
	C1112.295044,1297.121460 1112.569580,1296.966309 1113.115601,1296.812134 
	C1113.552856,1296.470215 1113.772949,1296.243652 1114.053955,1295.750244 
	C1114.420410,1295.323730 1114.700439,1295.139282 1115.160156,1294.808472 
	C1115.568237,1294.450684 1115.770996,1294.217163 1115.969238,1293.975830 
	C1115.990234,1293.990234 1115.961426,1293.948120 1116.144531,1293.806152 
	C1116.562378,1293.461426 1116.770752,1293.234741 1116.957031,1292.970215 
	C1116.961060,1292.956421 1116.989136,1292.951416 1117.172363,1292.802856 
	C1117.588989,1292.441040 1117.796265,1292.204956 1117.990479,1291.969971 
	C1118.003662,1291.994263 1117.951050,1291.978516 1118.139160,1291.823242 
	C1118.567749,1291.447266 1118.782104,1291.203613 1118.978882,1290.966309 
	C1118.987549,1290.995850 1118.930542,1290.973145 1119.122803,1290.823242 
	C1119.561157,1290.459106 1119.781006,1290.220581 1119.987305,1289.978882 
	C1120.000000,1290.000000 1119.958008,1289.974609 1120.144775,1289.817383 
	C1120.568970,1289.434937 1120.782227,1289.188721 1120.978149,1288.952759 
	C1120.984863,1288.983887 1120.923706,1288.966309 1121.154419,1288.776367 
	C1121.688232,1288.311401 1121.991333,1288.036377 1122.230957,1287.595703 
	C1122.167603,1287.429932 1122.408325,1287.169434 1122.408325,1287.169434 
	C1122.408325,1287.169434 1122.760742,1287.209106 1122.973511,1286.989868 
	C1123.451782,1286.470093 1123.717407,1286.169556 1123.965332,1285.902344 
	C1123.947510,1285.935669 1123.881592,1285.899048 1124.155762,1285.930786 
	C1124.613281,1285.974487 1124.796875,1285.986572 1125.224365,1286.162354 
	C1126.375854,1286.091187 1127.283447,1285.856201 1128.191040,1285.621338 
	C1127.793945,1285.080566 1127.396973,1284.539673 1126.991699,1283.729248 
	C1126.968994,1283.280151 1126.954590,1283.100708 1126.954102,1282.951050 
	C1126.968140,1282.980713 1126.902588,1282.980347 1127.098267,1282.816284 
	C1127.524170,1282.401489 1127.754395,1282.150757 1127.977295,1281.932251 
	C1127.969971,1281.964478 1127.917603,1281.924072 1128.205933,1281.831543 
	C1128.973633,1281.135010 1129.453125,1280.531128 1129.953369,1279.952881 
	C1129.973999,1279.978394 1129.908569,1279.984619 1130.102783,1279.822754 
	C1130.522827,1279.411255 1130.748901,1279.161865 1130.979980,1278.944458 
	C1130.984863,1278.976562 1130.920776,1278.966064 1131.112793,1278.809570 
	C1131.551025,1278.430542 1131.771729,1278.185303 1131.975220,1277.949707 
	C1131.983276,1277.982300 1131.925659,1277.948120 1132.192017,1277.967407 
	C1132.636108,1277.991577 1132.813843,1277.996460 1133.108154,1278.268311 
	C1134.073853,1278.700684 1135.258057,1279.310425 1135.720459,1278.962036 
	C1140.145386,1275.628662 1144.725220,1272.328857 1144.981445,1265.734131 
	C1144.961548,1265.295410 1144.947021,1265.120117 1144.953125,1264.945557 
	C1144.973511,1264.946167 1144.943237,1264.963989 1145.132446,1264.803223 
	C1145.563721,1264.415039 1145.783203,1264.167480 1145.978516,1263.932861 
	C1145.976685,1263.965698 1145.918823,1263.934326 1146.206055,1263.839111 
	C1146.961670,1263.132935 1147.430054,1262.521851 1147.926758,1261.927490 
	C1147.955078,1261.944092 1147.901733,1261.982666 1148.091309,1261.811768 
	C1148.513184,1261.391602 1148.745361,1261.142334 1148.988037,1260.932373 
	C1148.998535,1260.971558 1148.919922,1260.991699 1149.106689,1260.817383 
	C1149.518799,1260.386963 1149.744019,1260.130981 1149.968994,1259.921387 
	C1149.968628,1259.967773 1149.875854,1259.966431 1150.075439,1259.808472 
	C1150.507202,1259.403809 1150.739502,1259.157104 1150.972900,1258.946289 
	C1150.973877,1258.982056 1150.902100,1258.983398 1151.098389,1258.820068 
	C1151.523438,1258.404663 1151.752075,1258.152710 1151.980591,1257.936157 
	C1151.980347,1257.971436 1151.909912,1257.968994 1152.103516,1257.812256 
	C1152.544922,1257.432495 1152.768066,1257.187012 1152.970093,1256.951660 
	C1152.973999,1256.984497 1152.908203,1256.978027 1153.104492,1256.814941 
	C1153.530029,1256.400269 1153.759155,1256.148682 1153.986816,1255.936279 
	C1153.985352,1255.975464 1153.906982,1255.971680 1154.099609,1255.806641 
	C1154.516357,1255.388184 1154.740479,1255.134888 1154.963989,1254.926636 
	C1154.963501,1254.971680 1154.873413,1254.969727 1155.071045,1254.816772 
	C1155.524414,1254.449097 1155.754150,1254.209351 1155.978516,1253.971191 
	C1155.998901,1253.997437 1155.942749,1253.961792 1156.129150,1253.813232 
	C1156.554077,1253.452881 1156.766968,1253.217651 1156.977051,1252.976074 
	C1157.000000,1252.993042 1156.958374,1252.950684 1157.136475,1252.807129 
	C1157.542114,1252.459595 1157.739624,1252.229858 1158.006226,1251.735107 
	C1158.382812,1251.301514 1158.660278,1251.107056 1159.128662,1250.764160 
	C1159.561035,1250.397949 1159.777466,1250.157593 1159.974609,1249.934692 
	C1159.980591,1249.974976 1159.900024,1249.985840 1160.094727,1249.819092 
	C1160.521362,1249.401123 1160.753174,1249.150024 1160.980225,1248.938965 
	C1160.975342,1248.978882 1160.895508,1248.968018 1161.091553,1248.810059 
	C1161.519043,1248.409302 1161.750366,1248.166626 1161.985596,1247.956787 
	C1161.989380,1247.989868 1161.923096,1247.986084 1162.112061,1247.815674 
	C1162.528076,1247.393555 1162.755249,1247.141602 1162.983765,1246.933105 
	C1162.985107,1246.976685 1162.898071,1246.978638 1163.088013,1246.808472 
	C1163.511597,1246.391357 1163.745117,1246.144409 1163.980713,1245.941284 
	C1163.982666,1245.985107 1163.895020,1245.988281 1164.130615,1245.785645 
	C1164.696899,1245.315552 1165.027466,1245.048096 1165.258545,1244.625610 
	C1165.268433,1244.187500 1165.468262,1244.089844 1165.973755,1243.972046 
	C1166.448853,1243.473999 1166.708984,1243.181396 1166.971191,1242.933105 
	C1166.973145,1242.977417 1166.884644,1242.980591 1167.082275,1242.819580 
	C1167.512817,1242.411499 1167.745728,1242.164307 1167.979004,1241.950195 
	C1167.979370,1241.983154 1167.914795,1241.969604 1168.108154,1241.812012 
	C1168.549683,1241.431396 1168.771606,1241.184937 1168.976807,1240.947510 
	C1168.986450,1240.980469 1168.927856,1240.944824 1169.212402,1240.876709 
	C1169.985107,1240.498901 1170.473145,1240.189331 1170.995117,1239.645996 
	C1171.029053,1239.412109 1171.215576,1238.977905 1171.354004,1238.819580 
	C1171.666870,1238.440308 1171.817993,1238.204346 1171.972900,1237.976685 
	C1172.000000,1238.000000 1171.947266,1237.953491 1172.134399,1237.790527 
	C1172.558838,1237.394775 1172.773560,1237.142700 1172.970215,1236.916992 
	C1172.974609,1236.962891 1172.882812,1236.971191 1173.078003,1236.805176 
	C1173.506714,1236.390259 1173.740234,1236.141357 1173.972656,1235.937012 
	C1173.971558,1235.981689 1173.882324,1235.978760 1174.079834,1235.814941 
	C1174.510010,1235.400513 1174.742432,1235.149902 1174.977905,1234.936279 
	C1174.980835,1234.973145 1174.906982,1234.978149 1175.100098,1234.818848 
	C1175.517944,1234.413696 1175.742798,1234.167847 1175.972168,1233.953857 
	C1175.976807,1233.985840 1175.914062,1233.970947 1176.108887,1233.812134 
	C1176.529053,1233.405151 1176.754517,1233.156982 1176.982056,1232.942505 
	C1176.984253,1232.976196 1176.916870,1232.971436 1177.108643,1232.812500 
	C1177.547363,1232.429932 1177.769531,1232.183838 1177.969971,1231.948486 
	C1177.973145,1231.981689 1177.906982,1231.975098 1178.100098,1231.823486 
	C1178.541382,1231.457031 1178.763428,1231.218018 1178.979614,1230.974854 
	C1179.000000,1230.995117 1178.962402,1230.947998 1179.246826,1230.847412 
	C1179.984497,1230.126099 1180.437500,1229.505493 1180.937988,1228.944214 
	C1180.985352,1229.003540 1180.883301,1228.887695 1181.176025,1228.801758 
	C1181.954346,1228.110840 1182.439697,1227.505981 1182.937500,1226.932495 
	C1182.949829,1226.963989 1182.883057,1226.973999 1183.080566,1226.817139 
	C1183.507324,1226.415527 1183.736572,1226.170898 1183.973633,1225.957031 
	C1183.981445,1225.987915 1183.921265,1225.967285 1184.111084,1225.821533 
	C1184.544434,1225.468872 1184.761719,1225.237061 1184.959961,1224.973877 
	C1184.967407,1224.967407 1184.981323,1224.953369 1185.165771,1224.796631 
	C1185.583984,1224.414307 1185.795288,1224.169312 1185.982666,1223.937744 
	C1185.981201,1223.970459 1185.924438,1223.937866 1186.212646,1223.841797 
	C1186.977539,1223.135986 1187.454102,1222.526367 1187.946289,1221.946777 
	C1187.961914,1221.976807 1187.894653,1221.968750 1188.091797,1221.810669 
	C1188.519409,1221.406738 1188.749878,1221.160767 1188.983643,1220.948486 
	C1188.987061,1220.981934 1188.919678,1220.980225 1189.110229,1220.817871 
	C1189.524292,1220.408081 1189.747925,1220.160767 1189.974243,1219.946777 
	C1189.977051,1219.980347 1189.910034,1219.975830 1190.097900,1219.820190 
	C1190.527222,1219.446533 1190.740234,1219.203491 1191.007324,1218.688232 
	C1191.373779,1218.253052 1191.657593,1218.064819 1192.132324,1217.733887 
	C1192.563843,1217.377686 1192.780762,1217.142334 1192.978516,1216.927490 
	C1192.983032,1216.970093 1192.897949,1216.978760 1193.091797,1216.814453 
	C1193.513672,1216.399902 1193.741699,1216.149902 1193.969238,1215.936768 
	C1193.968750,1215.973877 1193.894775,1215.972168 1194.090820,1215.817139 
	C1194.539062,1215.443237 1194.765259,1215.199951 1194.975464,1214.963135 
	C1194.985596,1214.993896 1194.929810,1214.961060 1195.114502,1214.852661 
	C1195.538452,1214.586670 1195.757446,1214.404785 1196.235596,1214.078369 
	C1196.746338,1213.632935 1196.978027,1213.307739 1197.350586,1212.826904 
	C1197.670776,1212.455566 1197.826904,1212.224121 1197.974121,1211.983643 
	C1197.988159,1211.990234 1197.975342,1211.961914 1198.158447,1211.810303 
	C1198.575073,1211.441895 1198.782837,1211.202881 1198.981934,1210.967285 
	C1198.999023,1210.993164 1198.944702,1210.963013 1199.134888,1210.803711 
	C1199.567139,1210.415649 1199.783081,1210.164429 1199.973389,1209.930176 
	C1199.973999,1209.969604 1199.895264,1209.970215 1200.092896,1209.809570 
	C1200.516968,1209.396606 1200.743408,1209.144165 1200.968994,1208.931519 
	C1200.968018,1208.971313 1200.888428,1208.968506 1201.087158,1208.822266 
	C1201.541504,1208.467529 1201.771729,1208.234131 1201.988281,1207.987915 
	C1202.000000,1208.000000 1201.975342,1207.976196 1202.161377,1207.825562 
	C1202.585327,1207.459473 1202.797485,1207.221313 1202.995972,1206.978394 
	C1203.008057,1206.996460 1202.965942,1206.985474 1203.151489,1206.828125 
	C1203.573975,1206.447632 1203.785278,1206.202148 1203.978027,1205.964600 
	C1203.985107,1205.994751 1203.926758,1205.974609 1204.121338,1205.812988 
	C1204.540283,1205.399170 1204.764648,1205.146973 1204.984863,1204.934326 
	C1204.980835,1204.973755 1204.901855,1204.965332 1205.095459,1204.802490 
	C1205.517212,1204.390503 1205.745361,1204.141602 1205.971436,1203.937988 
	C1205.969604,1203.983276 1205.878906,1203.978638 1206.065430,1203.820557 
	C1206.495728,1203.447388 1206.709229,1203.205444 1206.984619,1202.707031 
	C1207.265381,1202.180664 1207.532593,1202.000610 1208.077637,1201.781128 
	C1208.508423,1201.377686 1208.740112,1201.130737 1208.969971,1200.929932 
	C1208.968262,1200.976074 1208.876099,1200.971680 1209.072388,1200.818237 
	C1209.525024,1200.452881 1209.756104,1200.216187 1209.980957,1199.977417 
	C1210.000000,1200.000000 1209.954590,1199.961304 1210.144165,1199.816650 
	C1210.576538,1199.464600 1210.794800,1199.234253 1211.053711,1198.733276 
	C1211.399048,1198.308838 1211.679321,1198.131592 1212.147461,1197.794922 
	C1212.572998,1197.405029 1212.788696,1197.155762 1212.976562,1196.920898 
	C1212.970581,1196.954224 1212.906982,1196.930542 1213.179932,1196.952881 
	C1213.635132,1196.981079 1213.817505,1196.986938 1214.000000,1196.992676 
M1119.596802,1320.552246 
	C1123.564453,1322.621216 1124.726807,1321.893921 1125.351074,1315.651489 
	C1122.979614,1317.428589 1121.322998,1318.669922 1119.596802,1320.552246 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1172.301392,1283.031372 
	C1171.796753,1278.167480 1176.057739,1276.386963 1178.917725,1273.547729 
	C1189.110718,1263.428711 1199.223267,1253.228516 1209.429199,1243.122314 
	C1210.925659,1241.640381 1212.761841,1240.501587 1214.782227,1239.138672 
	C1214.120728,1240.862305 1213.463745,1243.001831 1212.061401,1244.385742 
	C1198.984253,1257.290649 1185.779297,1270.066040 1172.301392,1283.031372 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1252.403320,1202.075684 
	C1252.040039,1201.943604 1252.031494,1201.754883 1252.008911,1201.283081 
	C1253.214966,1199.513672 1254.434937,1198.027344 1256.106934,1196.165771 
	C1257.039307,1195.193604 1257.519653,1194.596802 1258.000000,1194.000000 
	C1262.856812,1189.310669 1267.629395,1184.528442 1272.628662,1179.996216 
	C1273.903198,1178.840698 1275.896240,1178.477783 1277.820190,1177.939209 
	C1269.640137,1186.091553 1261.199097,1194.055298 1252.403320,1202.075684 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1239.490479,1216.058350 
	C1239.628174,1210.345093 1244.004517,1208.211304 1248.752441,1206.062622 
	C1246.026123,1209.374878 1242.934204,1212.707886 1239.490479,1216.058350 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1219.408203,1236.093994 
	C1220.349609,1231.087646 1224.138428,1228.393433 1228.795044,1226.123291 
	C1226.012939,1229.385254 1222.885742,1232.716797 1219.408203,1236.093994 
z"
      />
      <path
        fill="#E49F1B"
        opacity="1.000000"
        stroke="none"
        d="
M1304.516602,555.085205 
	C1295.909546,555.278809 1286.840576,555.399719 1277.404419,555.343140 
	C1286.042969,555.114624 1295.048828,555.063538 1304.516602,555.085205 
z"
      />
      <path
        fill="#E49F1B"
        opacity="1.000000"
        stroke="none"
        d="
M1453.520020,555.085205 
	C1445.237671,555.273193 1436.493164,555.387939 1427.386108,555.311401 
	C1435.701660,555.084106 1444.379639,555.048035 1453.520020,555.085205 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1355.407471,1503.027344 
	C1357.146606,1502.919067 1359.273560,1502.786865 1361.713379,1502.857300 
	C1359.949219,1503.041260 1357.872192,1503.022339 1355.407471,1503.027344 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1215.422119,1239.087646 
	C1216.098145,1238.424438 1217.123535,1237.724731 1218.496460,1237.010986 
	C1217.819824,1237.681763 1216.795654,1238.366455 1215.422119,1239.087646 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M551.110657,610.427429 
	C551.289001,611.476074 551.396057,612.932678 551.347046,614.676514 
	C551.140503,613.587646 551.089966,612.211548 551.110657,610.427429 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1364.059326,1503.428833 
	C1364.524292,1503.052979 1365.105103,1502.952515 1365.876465,1503.002686 
	C1365.436523,1503.337036 1364.805786,1503.520630 1364.059326,1503.428833 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1353.043457,1503.300659 
	C1353.385010,1503.029541 1353.859985,1503.022217 1354.690918,1503.022461 
	C1354.423584,1503.208130 1353.800171,1503.386230 1353.043457,1503.300659 
z"
      />
      <path
        fill="#E1971A"
        opacity="1.000000"
        stroke="none"
        d="
M551.111206,630.339844 
	C551.260498,630.547729 551.337219,631.078003 551.297607,631.788574 
	C551.133728,631.533386 551.086182,631.097778 551.111206,630.339844 
z"
      />
      <path
        fill="#E1971A"
        opacity="1.000000"
        stroke="none"
        d="
M551.132568,643.347351 
	C551.272034,643.552795 551.347778,644.080078 551.307495,644.787231 
	C551.150696,644.534485 551.109802,644.101807 551.132568,643.347351 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1230.278809,1225.916260 
	C1230.170044,1225.664185 1230.354614,1225.459961 1230.720093,1225.143921 
	C1230.770630,1225.324097 1230.661011,1225.634766 1230.278809,1225.916260 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1366.974121,1503.295166 
	C1367.082031,1503.043335 1367.289917,1503.037109 1367.809570,1503.039185 
	C1367.772217,1503.211792 1367.423096,1503.376221 1366.974121,1503.295166 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M581.936157,614.749023 
	C581.709412,614.787598 581.637756,614.543091 581.761475,614.143250 
	C581.823242,614.023560 581.957703,614.501648 581.936157,614.749023 
z"
      />
      <path
        fill="#E1971A"
        opacity="1.000000"
        stroke="none"
        d="
M551.128601,627.255981 
	C551.362610,627.235291 551.413269,627.479004 551.269470,627.859741 
	C551.203186,627.972961 551.073425,627.496582 551.128601,627.255981 
z"
      />
      <path
        fill="#E1971A"
        opacity="1.000000"
        stroke="none"
        d="
M551.141296,639.253296 
	C551.377808,639.242310 551.418030,639.487061 551.260620,639.860413 
	C551.190674,639.970642 551.072815,639.490723 551.141296,639.253296 
z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M1112.924072,1296.952393 
	C1112.569580,1296.966309 1112.295044,1297.121460 1111.778198,1297.746460 
	C1111.356934,1298.384277 1111.257812,1298.693848 1111.158691,1299.003296 
	C1103.507324,1306.410889 1095.777222,1313.739380 1088.219482,1321.241211 
	C1067.222900,1342.082031 1046.298096,1362.995117 1024.923340,1384.300049 
	C906.686340,1266.112183 788.435852,1147.910889 670.089661,1029.613892 
	C767.072510,932.571777 863.965759,835.619324 960.981934,738.349487 
	C962.651062,736.592957 964.197205,735.153809 966.132446,733.306152 
	C966.685791,732.602234 966.849915,732.306763 967.014038,732.011292 
	C967.014099,732.011292 967.000000,732.000000 967.244141,731.919922 
	C967.675537,731.565063 967.862793,731.290283 968.050049,731.015564 
	C968.050049,731.015564 968.005920,730.990906 968.275879,730.953064 
	C968.735657,730.616089 968.925354,730.316956 969.115112,730.017822 
	C969.115112,730.017822 969.017700,730.013428 969.281982,729.976196 
	C969.735413,729.630676 969.924622,729.322388 970.113892,729.014160 
	C970.113892,729.014160 970.011780,729.015869 970.272827,728.977356 
	C970.714417,728.635864 970.895020,728.332825 971.075562,728.029785 
	C971.417053,727.992249 971.683289,727.830627 972.224792,727.245544 
	C972.758545,726.636780 972.941772,726.327515 973.124939,726.018250 
	C973.124939,726.018250 973.059814,726.040344 973.355957,725.951904 
	C974.142883,724.590210 974.633667,723.316956 975.124512,722.043762 
	C975.124512,722.043762 975.580566,721.988953 976.054077,721.940247 
	C976.631409,721.599182 976.735229,721.306824 976.839111,721.014526 
	C977.051086,720.698853 977.263123,720.383118 978.081238,720.007080 
	C980.484863,718.316650 982.282349,716.686646 984.079834,715.056641 
	C984.079773,715.056580 984.018250,715.032288 984.271118,714.972046 
	C984.713257,714.612671 984.902588,714.313599 985.091919,714.014526 
	C985.091858,714.014465 985.023743,714.018616 985.277832,713.970154 
	C985.629150,713.620056 985.726318,713.318420 985.823486,713.016724 
	C991.295349,707.769836 996.885986,702.640198 1002.210022,697.247375 
	C1009.359497,690.005371 1016.260559,682.517944 1023.425903,675.292175 
	C1023.927734,674.786133 1026.159546,675.132568 1026.865967,675.821655 
	C1038.026245,686.709229 1049.078613,697.707458 1060.134155,708.702087 
	C1068.820435,717.340393 1077.539917,725.946899 1086.132324,734.677979 
	C1099.050659,747.804993 1111.763794,761.135681 1124.765625,774.178772 
	C1136.171143,785.620544 1147.978149,796.662231 1159.383667,808.104004 
	C1174.040283,822.807190 1188.353027,837.853699 1203.034668,852.531311 
	C1219.064331,868.556519 1235.445068,884.230286 1251.488525,900.241760 
	C1265.653198,914.378052 1279.474121,928.858398 1293.616943,943.016968 
	C1304.230591,953.642517 1315.213013,963.899231 1325.844238,974.507629 
	C1333.639771,982.286316 1341.053955,990.445923 1348.780884,998.295044 
	C1357.292603,1006.941345 1365.961548,1015.432739 1374.576538,1023.977051 
	C1376.250000,1025.636719 1377.989746,1027.229370 1380.443115,1029.559692 
	C1337.127808,1072.726685 1293.667114,1116.038574 1249.751709,1159.784424 
	C1247.171143,1162.674805 1245.158203,1165.240845 1242.898926,1167.567383 
	C1235.633179,1175.049805 1228.276489,1182.443726 1220.622681,1189.929199 
	C1219.479614,1190.659912 1218.668701,1191.333374 1217.857788,1192.006714 
	C1217.281738,1192.482056 1216.705566,1192.957520 1215.769775,1193.766235 
	C1215.238159,1194.391724 1215.066162,1194.683838 1214.894043,1194.975830 
	C1214.894043,1194.975830 1214.974731,1194.985352 1214.714844,1195.027832 
	C1214.265503,1195.375000 1214.076050,1195.679565 1213.886475,1195.984131 
	C1213.886475,1195.984131 1213.949585,1195.955811 1213.680176,1196.000977 
	C1213.242798,1196.340942 1213.074951,1196.635742 1212.906982,1196.930542 
	C1212.906982,1196.930542 1212.970581,1196.954224 1212.728760,1197.017090 
	C1212.311157,1197.371338 1212.135376,1197.662842 1211.959473,1197.954346 
	C1211.679321,1198.131592 1211.399048,1198.308838 1210.811279,1198.809692 
	C1210.320801,1199.409424 1210.137695,1199.685425 1209.954590,1199.961304 
	C1209.954590,1199.961304 1210.000000,1200.000000 1209.718994,1200.039551 
	C1209.250610,1200.376587 1209.063354,1200.674072 1208.876099,1200.971680 
	C1208.876099,1200.971680 1208.968262,1200.976074 1208.701660,1201.001953 
	C1208.249512,1201.330933 1208.063965,1201.634277 1207.878540,1201.937622 
	C1207.532593,1202.000610 1207.265381,1202.180664 1206.734131,1202.780762 
	C1206.220581,1203.382080 1206.049683,1203.680420 1205.878906,1203.978638 
	C1205.878906,1203.978638 1205.969604,1203.983276 1205.700928,1204.009277 
	C1205.255493,1204.345215 1205.078735,1204.655273 1204.901855,1204.965332 
	C1204.901855,1204.965332 1204.980835,1204.973755 1204.738159,1205.038330 
	C1204.305908,1205.393311 1204.116333,1205.683960 1203.926758,1205.974609 
	C1203.926758,1205.974609 1203.985107,1205.994751 1203.722290,1206.040039 
	C1203.294922,1206.385376 1203.130371,1206.685425 1202.965942,1206.985474 
	C1202.965942,1206.985474 1203.008057,1206.996460 1202.762695,1207.074219 
	C1202.336792,1207.426758 1202.156128,1207.701538 1201.975342,1207.976196 
	C1201.975342,1207.976196 1202.000000,1208.000000 1201.728760,1208.047363 
	C1201.267700,1208.385986 1201.078003,1208.677246 1200.888428,1208.968506 
	C1200.888428,1208.968506 1200.968018,1208.971313 1200.709717,1209.018311 
	C1200.265869,1209.366943 1200.080566,1209.668579 1199.895264,1209.970215 
	C1199.895264,1209.970215 1199.973999,1209.969604 1199.722778,1210.022949 
	C1199.296021,1210.371948 1199.120361,1210.667480 1198.944702,1210.963013 
	C1198.944702,1210.963013 1198.999023,1210.993164 1198.739990,1211.051270 
	C1198.312500,1211.393555 1198.143921,1211.677734 1197.975342,1211.961914 
	C1197.975342,1211.961914 1197.988159,1211.990234 1197.736816,1212.055176 
	C1197.134033,1212.324707 1197.042114,1212.612061 1197.209717,1212.982544 
	C1196.978027,1213.307739 1196.746338,1213.632935 1195.986328,1214.050293 
	C1195.282104,1214.415527 1195.105957,1214.688232 1194.929688,1214.960938 
	C1194.929810,1214.961060 1194.985596,1214.993896 1194.719727,1215.041382 
	C1194.267578,1215.383301 1194.081177,1215.677734 1193.894775,1215.972168 
	C1193.894775,1215.972168 1193.968750,1215.973877 1193.707275,1216.019287 
	C1193.263184,1216.369385 1193.080566,1216.674072 1192.897949,1216.978760 
	C1192.897949,1216.978760 1192.983032,1216.970093 1192.727295,1217.001099 
	C1192.294800,1217.313599 1192.118042,1217.595093 1191.941284,1217.876587 
	C1191.657593,1218.064819 1191.373779,1218.253052 1190.760254,1218.769409 
	C1190.256836,1219.390259 1190.083374,1219.682983 1189.909912,1219.975830 
	C1189.910034,1219.975830 1189.977051,1219.980347 1189.714355,1220.026611 
	C1189.274414,1220.375366 1189.097046,1220.677734 1188.919678,1220.980225 
	C1188.919678,1220.980225 1188.987061,1220.981934 1188.718018,1221.020752 
	C1188.264160,1221.362671 1188.079346,1221.665771 1187.894653,1221.968750 
	C1187.894653,1221.968750 1187.961914,1221.976807 1187.669800,1222.055908 
	C1186.893311,1222.735840 1186.408813,1223.336914 1185.924438,1223.937866 
	C1185.924438,1223.937866 1185.981201,1223.970459 1185.741455,1224.034424 
	C1185.328247,1224.383301 1185.154785,1224.668335 1184.981323,1224.953369 
	C1184.981323,1224.953369 1184.967407,1224.967407 1184.713013,1225.042969 
	C1184.279541,1225.401489 1184.100464,1225.684448 1183.921265,1225.967285 
	C1183.921265,1225.967285 1183.981445,1225.987915 1183.715332,1226.035400 
	C1183.260376,1226.380005 1183.071777,1226.677002 1182.883057,1226.973999 
	C1182.883057,1226.973999 1182.949829,1226.963989 1182.620117,1226.988525 
	C1181.821411,1227.637817 1181.352295,1228.262817 1180.883301,1228.887695 
	C1180.883301,1228.887695 1180.985352,1229.003540 1180.660645,1229.043091 
	C1179.878174,1229.704468 1179.420288,1230.326172 1178.962402,1230.947998 
	C1178.962402,1230.947998 1179.000000,1230.995117 1178.729736,1231.052979 
	C1178.275391,1231.399048 1178.091187,1231.687012 1177.906982,1231.975098 
	C1177.906982,1231.975098 1177.973145,1231.981689 1177.710693,1232.025269 
	C1177.271240,1232.369751 1177.093994,1232.670532 1176.916870,1232.971436 
	C1176.916870,1232.971436 1176.984253,1232.976196 1176.726074,1233.029297 
	C1176.283203,1233.378418 1176.098633,1233.674683 1175.914062,1233.970947 
	C1175.914062,1233.970947 1175.976807,1233.985840 1175.715332,1234.034790 
	C1175.271484,1234.381958 1175.089233,1234.680054 1174.906982,1234.978149 
	C1174.906982,1234.978149 1174.980835,1234.973145 1174.715088,1235.019897 
	C1174.260254,1235.370728 1174.071289,1235.674683 1173.882324,1235.978760 
	C1173.882324,1235.978760 1173.971558,1235.981689 1173.702881,1236.011597 
	C1173.250366,1236.351440 1173.066650,1236.661377 1172.882812,1236.971191 
	C1172.882812,1236.971191 1172.974609,1236.962891 1172.711914,1237.003418 
	C1172.281982,1237.347046 1172.114624,1237.650269 1171.947266,1237.953491 
	C1171.947266,1237.953491 1172.000000,1238.000000 1171.746094,1238.065186 
	C1171.153564,1238.332520 1171.061401,1238.615112 1171.215576,1238.977905 
	C1171.215576,1238.977905 1171.029053,1239.412109 1170.690674,1239.704346 
	C1169.877563,1240.312744 1169.402710,1240.628784 1168.927979,1240.944824 
	C1168.927856,1240.944824 1168.986450,1240.980469 1168.717041,1241.023438 
	C1168.270020,1241.367432 1168.092407,1241.668579 1167.914795,1241.969604 
	C1167.914795,1241.969604 1167.979370,1241.983154 1167.720703,1242.036133 
	C1167.269531,1242.386353 1167.077148,1242.683472 1166.884644,1242.980591 
	C1166.884644,1242.980591 1166.973145,1242.977417 1166.674561,1243.033936 
	C1166.170288,1243.453003 1165.964600,1243.815308 1165.758789,1244.177612 
	C1165.468262,1244.089844 1165.268433,1244.187500 1164.908691,1244.708496 
	C1164.403809,1245.293701 1164.149414,1245.640991 1163.895020,1245.988281 
	C1163.895020,1245.988281 1163.982666,1245.985107 1163.707642,1246.011475 
	C1163.254395,1246.351318 1163.076294,1246.665039 1162.898071,1246.978638 
	C1162.898071,1246.978638 1162.985107,1246.976685 1162.718262,1247.017090 
	C1162.275391,1247.367065 1162.099243,1247.676514 1161.923096,1247.986084 
	C1161.923096,1247.986084 1161.989380,1247.989868 1161.718018,1248.022949 
	C1161.262817,1248.359863 1161.079102,1248.663940 1160.895508,1248.968018 
	C1160.895508,1248.968018 1160.975342,1248.978882 1160.721436,1249.030518 
	C1160.278442,1249.383301 1160.089233,1249.684570 1159.900024,1249.985840 
	C1159.900024,1249.985840 1159.980591,1249.974976 1159.718994,1250.007202 
	C1159.284180,1250.330566 1159.111084,1250.621704 1158.937866,1250.912720 
	C1158.660278,1251.107056 1158.382812,1251.301514 1157.765869,1251.799072 
	C1157.270508,1252.384888 1157.114380,1252.667847 1156.958374,1252.950684 
	C1156.958374,1252.950684 1157.000000,1252.993042 1156.737793,1253.059937 
	C1156.297852,1253.405151 1156.120361,1253.683472 1155.942749,1253.961792 
	C1155.942749,1253.961792 1155.998901,1253.997437 1155.718506,1254.039551 
	C1155.249878,1254.377686 1155.061646,1254.673706 1154.873413,1254.969727 
	C1154.873413,1254.969727 1154.963501,1254.971680 1154.698730,1255.007568 
	C1154.258423,1255.352905 1154.082764,1255.662231 1153.906982,1255.971680 
	C1153.906982,1255.971680 1153.985352,1255.975464 1153.732910,1256.032715 
	C1153.289795,1256.386108 1153.098999,1256.682007 1152.908203,1256.978027 
	C1152.908203,1256.978027 1152.973999,1256.984497 1152.710571,1257.027100 
	C1152.268066,1257.369507 1152.088989,1257.669312 1151.909912,1257.968994 
	C1151.909912,1257.968994 1151.980347,1257.971436 1151.726929,1258.032227 
	C1151.282959,1258.389893 1151.092529,1258.686646 1150.902100,1258.983398 
	C1150.902100,1258.983398 1150.973877,1258.982056 1150.709351,1259.022705 
	C1150.255127,1259.364258 1150.065552,1259.665405 1149.875854,1259.966431 
	C1149.875854,1259.966431 1149.968628,1259.967773 1149.707153,1260.013916 
	C1149.270386,1260.370483 1149.095215,1260.681152 1148.919922,1260.991699 
	C1148.919922,1260.991699 1148.998535,1260.971558 1148.713501,1261.002441 
	C1148.252930,1261.349854 1148.077271,1261.666260 1147.901733,1261.982666 
	C1147.901733,1261.982666 1147.955078,1261.944092 1147.651611,1262.034180 
	C1146.871582,1262.727539 1146.395142,1263.330933 1145.918701,1263.934326 
	C1145.918823,1263.934326 1145.976685,1263.965698 1145.728027,1264.024170 
	C1145.300537,1264.376465 1145.121948,1264.670166 1144.943237,1264.963989 
	C1144.943237,1264.963989 1144.973511,1264.946167 1144.707397,1265.015015 
	C1144.265869,1265.366577 1144.090332,1265.649170 1143.957520,1265.963501 
	C1144.000000,1265.995117 1143.922485,1265.922485 1143.645508,1266.023560 
	C1142.882568,1266.728149 1142.396484,1267.331665 1141.940674,1267.950806 
	C1141.971069,1267.966553 1141.960693,1267.898926 1141.702637,1267.983398 
	C1141.254395,1268.365967 1141.064087,1268.664185 1140.914307,1268.959473 
	C1140.954834,1268.956543 1140.947998,1268.875732 1140.688721,1268.964355 
	C1140.259888,1269.362793 1140.090454,1269.672607 1139.954102,1269.984497 
	C1139.987305,1269.986694 1139.980347,1269.920532 1139.714844,1269.989258 
	C1139.262451,1270.359375 1139.075562,1270.660889 1138.923828,1270.965576 
	C1138.958740,1270.968628 1138.961304,1270.898315 1138.708618,1270.988281 
	C1138.282471,1271.377319 1138.109009,1271.676392 1137.965332,1271.982666 
	C1137.994873,1271.989868 1137.971191,1271.933716 1137.729980,1272.021484 
	C1137.309570,1272.390869 1137.130249,1272.672241 1136.975586,1272.976807 
	C1137.000000,1273.000000 1136.949585,1272.952881 1136.694580,1273.017334 
	C1136.266724,1273.375366 1136.093872,1273.669067 1135.951538,1273.973877 
	C1135.982056,1273.985107 1135.960205,1273.923828 1135.706055,1274.001709 
	C1135.281982,1274.376099 1135.112183,1274.672729 1134.970093,1274.980469 
	C1134.997925,1274.991577 1134.965332,1274.941162 1134.726196,1275.031006 
	C1134.306641,1275.400269 1134.126099,1275.679932 1133.971924,1275.979736 
	C1133.998169,1276.000000 1133.956299,1275.949219 1133.704102,1276.012451 
	C1133.285034,1276.365479 1133.118042,1276.655518 1132.966187,1276.970337 
	C1132.981201,1276.995117 1132.940430,1276.946411 1132.698120,1277.025391 
	C1132.279175,1277.385620 1132.102417,1277.666870 1131.925659,1277.948120 
	C1131.925659,1277.948120 1131.983276,1277.982300 1131.718262,1278.027588 
	C1131.275757,1278.370605 1131.098267,1278.668335 1130.920898,1278.966064 
	C1130.920776,1278.966064 1130.984863,1278.976562 1130.727295,1279.037109 
	C1130.282715,1279.393311 1130.095703,1279.688965 1129.908569,1279.984619 
	C1129.908569,1279.984619 1129.973999,1279.978394 1129.671143,1280.043335 
	C1128.884766,1280.713623 1128.401245,1281.318848 1127.917603,1281.924072 
	C1127.917603,1281.924072 1127.969971,1281.964478 1127.717773,1282.020996 
	C1127.277954,1282.378418 1127.090210,1282.679321 1126.902588,1282.980347 
	C1126.902588,1282.980347 1126.968140,1282.980713 1126.705444,1283.027832 
	C1126.268555,1283.363770 1126.094360,1283.652710 1125.951294,1283.954468 
	C1125.982422,1283.967285 1125.965820,1283.902100 1125.709717,1283.991211 
	C1125.269531,1284.381348 1125.085327,1284.682373 1124.928467,1284.964600 
	C1124.955811,1284.945923 1124.903320,1284.905273 1124.652710,1284.971436 
	C1124.228638,1285.324707 1124.055054,1285.611938 1123.881592,1285.899048 
	C1123.881592,1285.899048 1123.947510,1285.935669 1123.669189,1286.019043 
	C1123.180786,1286.471313 1122.970703,1286.840210 1122.760742,1287.209106 
	C1122.760742,1287.209106 1122.408325,1287.169434 1122.408325,1287.169434 
	C1122.408325,1287.169434 1122.167603,1287.429932 1121.902832,1287.694214 
	C1121.399902,1288.294434 1121.161865,1288.630371 1120.923706,1288.966309 
	C1120.923706,1288.966309 1120.984863,1288.983887 1120.734009,1289.046387 
	C1120.308105,1289.397339 1120.133057,1289.686035 1119.958008,1289.974609 
	C1119.958008,1289.974609 1120.000000,1290.000000 1119.739136,1290.058105 
	C1119.295654,1290.401733 1119.113037,1290.687378 1118.930420,1290.973145 
	C1118.930542,1290.973145 1118.987549,1290.995850 1118.724854,1291.042725 
	C1118.291748,1291.385742 1118.121338,1291.682129 1117.950928,1291.978516 
	C1117.951050,1291.978516 1118.003662,1291.994263 1117.755249,1292.058838 
	C1117.334351,1292.399414 1117.161743,1292.675415 1116.989136,1292.951416 
	C1116.989136,1292.951416 1116.961060,1292.956421 1116.711548,1293.030396 
	C1116.295166,1293.385620 1116.128296,1293.666870 1115.961426,1293.948120 
	C1115.961426,1293.948120 1115.990234,1293.990234 1115.725098,1294.046509 
	C1115.300171,1294.386841 1115.140381,1294.670898 1114.980469,1294.954956 
	C1114.700439,1295.139282 1114.420410,1295.323730 1113.808594,1295.816162 
	C1113.292480,1296.400269 1113.108276,1296.676270 1112.924072,1296.952393 
M844.341919,1152.677734 
	C850.544983,1154.734131 856.747986,1156.790527 863.604797,1158.931152 
	C864.041687,1158.974976 864.478577,1159.018677 865.078369,1159.535767 
	C865.370483,1159.640259 865.662659,1159.744629 866.613220,1160.134644 
	C867.728149,1160.451294 868.843079,1160.767944 870.388062,1161.692627 
	C878.293091,1164.323120 886.225891,1166.873535 894.096436,1169.603394 
	C904.764648,1173.303223 915.343689,1177.262817 926.036926,1180.886719 
	C938.396973,1185.075317 950.857178,1188.967163 963.241333,1193.085815 
	C974.841675,1196.943726 986.399597,1200.929199 998.642761,1205.208984 
	C1002.351379,1206.457764 1006.059998,1207.706543 1010.524841,1209.163330 
	C1021.028137,1215.412354 1031.234985,1214.182373 1042.016235,1207.987793 
	C1047.343994,1206.281250 1052.671631,1204.574707 1058.739502,1202.714722 
	C1069.140991,1199.134888 1079.542358,1195.555054 1090.712891,1191.971069 
	C1104.893188,1187.063232 1119.071045,1182.148438 1133.254150,1177.248779 
	C1150.685547,1171.227295 1168.137451,1165.264282 1185.547852,1159.182373 
	C1193.016479,1156.573486 1200.402832,1153.729126 1208.137207,1150.214722 
	C1208.375122,1148.427856 1208.817139,1146.641113 1208.819702,1144.854004 
	C1208.848145,1124.912598 1208.808838,1104.971191 1208.926758,1084.097534 
	C1208.953369,1055.813110 1209.036621,1027.528442 1208.984985,999.244141 
	C1208.954590,982.681091 1208.657593,966.118713 1208.583618,949.555542 
	C1208.550049,942.055786 1208.767456,934.554993 1208.997314,926.118347 
	C1208.930664,921.169922 1209.120483,916.197449 1208.658569,911.286194 
	C1208.495972,909.557739 1207.093750,907.048096 1205.645264,906.492981 
	C1199.190430,904.019409 1192.514160,902.124207 1185.623779,899.545837 
	C1185.100464,899.415833 1184.577148,899.285828 1183.537842,899.005432 
	C1183.366089,898.954285 1183.194458,898.903137 1182.612061,898.337219 
	C1181.750122,898.208557 1180.888062,898.079895 1179.575928,897.441589 
	C1178.052246,897.069824 1176.528442,896.698059 1174.356079,895.949707 
	C1169.573486,894.357483 1164.791016,892.765320 1159.350220,890.826111 
	C1144.573730,885.584839 1129.833984,880.236755 1115.010864,875.131104 
	C1098.760498,869.533691 1082.440186,864.139160 1066.137207,858.695312 
	C1053.137085,854.354370 1040.117310,850.072205 1026.466187,845.380981 
	C1025.156372,845.342651 1023.725464,844.938049 1022.556030,845.324524 
	C1013.938843,848.172302 1005.377258,851.187927 996.791809,854.132263 
	C967.700378,864.108704 938.575439,873.988770 909.525635,884.085022 
	C893.968994,889.491638 878.540039,895.265503 862.735107,900.450134 
	C862.142578,900.599792 861.550049,900.749512 860.226746,901.011475 
	C857.818848,902.012451 855.411011,903.013428 852.330811,904.027466 
	C851.547729,904.339050 850.764648,904.650635 849.199646,905.045227 
	C841.219421,906.788574 841.173035,906.788086 841.093811,914.414978 
	C840.997314,923.701233 840.915894,932.988586 840.955627,942.274963 
	C841.085388,972.639404 841.381897,1003.003418 841.407959,1033.367798 
	C841.433594,1063.244873 841.207642,1093.122314 840.965515,1123.930908 
	C840.944397,1131.733643 840.757324,1139.542480 841.038208,1147.334351 
	C841.096375,1148.948486 842.893005,1150.500000 844.341919,1152.677734 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1111.369019,1299.001587 
	C1111.257812,1298.693848 1111.356934,1298.384277 1111.705200,1297.979614 
	C1111.965210,1298.069946 1111.976196,1298.255493 1111.993652,1298.719482 
	C1112.000000,1298.998047 1111.579346,1298.999878 1111.369019,1299.001587 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1220.954834,1189.871826 
	C1228.276489,1182.443726 1235.633179,1175.049805 1242.898926,1167.567383 
	C1245.158203,1165.240845 1247.171143,1162.674805 1249.614990,1160.091309 
	C1249.671021,1161.416992 1249.920410,1163.362305 1249.068359,1164.247070 
	C1240.075195,1173.586548 1230.920044,1182.770386 1221.745117,1191.932861 
	C1221.299438,1192.377808 1220.256958,1192.225220 1219.478516,1192.023438 
	C1219.961304,1191.088013 1220.458130,1190.479980 1220.954834,1189.871826 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M898.721924,786.152954 
	C896.982056,783.012634 897.743591,781.623047 902.990906,780.332764 
	C901.666504,782.248535 900.342102,784.164246 898.721924,786.152954 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M983.720947,715.073853 
	C982.282349,716.686646 980.484863,718.316650 978.345215,719.886108 
	C979.789368,718.247375 981.575745,716.669250 983.720947,715.073853 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M974.801392,722.181152 
	C974.633667,723.316956 974.142883,724.590210 973.372070,725.982300 
	C973.554077,724.840271 974.016235,723.579407 974.801392,722.181152 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1220.622681,1189.929199 
	C1220.458130,1190.479980 1219.961304,1191.088013 1219.218262,1191.866455 
	C1218.786621,1192.030762 1218.600952,1192.024658 1218.136475,1192.012573 
	C1218.668701,1191.333374 1219.479614,1190.659912 1220.622681,1189.929199 
z"
      />
      <path
        fill="#E3981A"
        opacity="1.000000"
        stroke="none"
        d="
M1257.720703,1194.104736 
	C1257.519653,1194.596802 1257.039307,1195.193604 1256.279541,1195.895264 
	C1256.480469,1195.403198 1256.960815,1194.806396 1257.720703,1194.104736 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1215.081177,1194.823242 
	C1215.066162,1194.683838 1215.238159,1194.391724 1215.658203,1194.028076 
	C1215.722290,1194.220459 1215.509644,1194.458496 1215.081177,1194.823242 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1213.179932,1196.952881 
	C1213.074951,1196.635742 1213.242798,1196.340942 1213.662109,1195.971436 
	C1213.932983,1196.079102 1213.952271,1196.261475 1213.985840,1196.718262 
	C1213.817505,1196.986938 1213.635132,1196.981079 1213.179932,1196.952881 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1214.081055,1195.816528 
	C1214.076050,1195.679565 1214.265503,1195.375000 1214.719238,1194.987427 
	C1214.747559,1195.152588 1214.511719,1195.400757 1214.081055,1195.816528 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M985.677002,713.183228 
	C985.726318,713.318420 985.629150,713.620056 985.283569,714.003784 
	C985.183411,713.829102 985.348450,713.583679 985.677002,713.183228 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M984.899475,714.182495 
	C984.902588,714.313599 984.713257,714.612671 984.267212,715.004883 
	C984.242676,714.848816 984.474854,714.599670 984.899475,714.182495 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M966.835327,732.173584 
	C966.849915,732.306763 966.685791,732.602234 966.272095,732.980469 
	C966.215698,732.804749 966.427002,732.562378 966.835327,732.173584 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M967.866272,731.170898 
	C967.862793,731.290283 967.675537,731.565063 967.250122,731.927246 
	C967.220276,731.770447 967.443787,731.540894 967.866272,731.170898 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M968.918701,730.174072 
	C968.925354,730.316956 968.735657,730.616089 968.281372,730.977783 
	C968.251953,730.803589 968.487061,730.566956 968.918701,730.174072 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M969.917664,729.183716 
	C969.924622,729.322388 969.735413,729.630676 969.280029,730.024963 
	C969.249695,729.858398 969.485596,729.605835 969.917664,729.183716 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M970.883789,728.195496 
	C970.895020,728.332825 970.714417,728.635864 970.273804,729.028442 
	C970.239807,728.865723 970.465881,728.613464 970.883789,728.195496 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M972.929321,726.182983 
	C972.941772,726.327515 972.758545,726.636780 972.309509,727.021179 
	C972.273743,726.846741 972.503723,726.597229 972.929321,726.182983 
z"
      />
      <path
        fill="#E49C1A"
        opacity="1.000000"
        stroke="none"
        d="
M976.690918,721.140991 
	C976.735229,721.306824 976.631409,721.599182 976.271362,721.865845 
	C976.175842,721.635193 976.351746,721.444336 976.690918,721.140991 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1144.986572,1265.997803 
	C1144.725220,1272.328857 1140.145386,1275.628662 1135.720459,1278.962036 
	C1135.258057,1279.310425 1134.073853,1278.700684 1133.103760,1278.004150 
	C1132.968750,1277.297363 1132.954590,1277.121826 1132.940430,1276.946411 
	C1132.940430,1276.946411 1132.981201,1276.995117 1133.152954,1276.823975 
	C1133.563599,1276.443848 1133.774048,1276.209351 1133.956299,1275.949219 
	C1133.956299,1275.949219 1133.998169,1276.000000 1134.163574,1275.832764 
	C1134.572876,1275.452026 1134.785034,1275.210571 1134.965332,1274.941162 
	C1134.965332,1274.941162 1134.997925,1274.991577 1135.159058,1274.826416 
	C1135.559937,1274.438354 1135.773315,1274.192505 1135.960205,1273.923828 
	C1135.960205,1273.923828 1135.982056,1273.985107 1136.141846,1273.824463 
	C1136.545288,1273.452026 1136.761353,1273.215088 1136.949585,1272.952881 
	C1136.949585,1272.952881 1137.000000,1273.000000 1137.166992,1272.829346 
	C1137.578003,1272.444824 1137.790283,1272.203125 1137.971191,1271.933716 
	C1137.971191,1271.933716 1137.994873,1271.989868 1138.156494,1271.823730 
	C1138.559937,1271.427979 1138.774414,1271.174805 1138.961304,1270.898315 
	C1138.961304,1270.898315 1138.958740,1270.968628 1139.121704,1270.809082 
	C1139.516602,1270.406616 1139.748413,1270.163574 1139.980347,1269.920532 
	C1139.980347,1269.920532 1139.987305,1269.986694 1140.141602,1269.814941 
	C1140.513184,1269.387329 1140.730591,1269.131592 1140.947998,1268.875732 
	C1140.947998,1268.875732 1140.954834,1268.956543 1141.115601,1268.802734 
	C1141.504517,1268.399048 1141.732544,1268.148926 1141.960693,1267.898926 
	C1141.960693,1267.898926 1141.971069,1267.966553 1142.229492,1267.856445 
	C1142.966187,1267.138306 1143.444336,1266.530396 1143.922363,1265.922485 
	C1143.922485,1265.922485 1144.000000,1265.995117 1144.225098,1265.986816 
	C1144.628784,1265.984985 1144.807617,1265.991333 1144.986572,1265.997803 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1119.631592,1320.231689 
	C1121.322998,1318.669922 1122.979614,1317.428589 1125.351074,1315.651489 
	C1124.726807,1321.893921 1123.564453,1322.621216 1119.631592,1320.231689 
z"
      />
      <path
        fill="#E49C1B"
        opacity="1.000000"
        stroke="none"
        d="
M1127.000000,1283.998901 
	C1127.396973,1284.539673 1127.793945,1285.080566 1128.191040,1285.621338 
	C1127.283447,1285.856201 1126.375854,1286.091187 1125.212891,1285.888550 
	C1124.939331,1285.269043 1124.921265,1285.087158 1124.903320,1284.905273 
	C1124.903320,1284.905273 1124.955811,1284.945923 1125.121582,1284.799805 
	C1125.513550,1284.403198 1125.739624,1284.152588 1125.965820,1283.902100 
	C1125.965820,1283.902100 1125.982422,1283.967285 1126.221191,1283.976318 
	C1126.640015,1283.989990 1126.819946,1283.994385 1127.000000,1283.998901 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1181.176025,1228.801758 
	C1181.352295,1228.262817 1181.821411,1227.637817 1182.607788,1226.957031 
	C1182.439697,1227.505981 1181.954346,1228.110840 1181.176025,1228.801758 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1179.246826,1230.847412 
	C1179.420288,1230.326172 1179.878174,1229.704468 1180.613281,1228.983765 
	C1180.437500,1229.505493 1179.984497,1230.126099 1179.246826,1230.847412 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1146.206055,1263.839111 
	C1146.395142,1263.330933 1146.871582,1262.727539 1147.623169,1262.017578 
	C1147.430054,1262.521851 1146.961670,1263.132935 1146.206055,1263.839111 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1186.212646,1223.841797 
	C1186.408813,1223.336914 1186.893311,1222.735840 1187.654297,1222.025757 
	C1187.454102,1222.526367 1186.977539,1223.135986 1186.212646,1223.841797 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1128.205933,1281.831543 
	C1128.401245,1281.318848 1128.884766,1280.713623 1129.650391,1280.017822 
	C1129.453125,1280.531128 1128.973633,1281.135010 1128.205933,1281.831543 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1169.212524,1240.876709 
	C1169.402710,1240.628784 1169.877563,1240.312744 1170.656738,1239.938232 
	C1170.473145,1240.189331 1169.985107,1240.498901 1169.212524,1240.876709 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1115.160156,1294.808472 
	C1115.140381,1294.670898 1115.300171,1294.386841 1115.704102,1294.032104 
	C1115.770996,1294.217163 1115.568237,1294.450684 1115.160156,1294.808472 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1113.115601,1296.812134 
	C1113.108276,1296.676270 1113.292480,1296.400269 1113.722046,1296.058228 
	C1113.772949,1296.243652 1113.552856,1296.470215 1113.115601,1296.812134 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1144.981445,1265.734131 
	C1144.807617,1265.991333 1144.628784,1265.984985 1144.182495,1265.955200 
	C1144.090332,1265.649170 1144.265869,1265.366577 1144.687012,1265.014404 
	C1144.947021,1265.120117 1144.961548,1265.295410 1144.981445,1265.734131 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1145.132446,1264.803223 
	C1145.121948,1264.670166 1145.300537,1264.376465 1145.729736,1263.991211 
	C1145.783203,1264.167480 1145.563721,1264.415039 1145.132446,1264.803223 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1148.091309,1261.811768 
	C1148.077271,1261.666260 1148.252930,1261.349854 1148.703003,1260.963257 
	C1148.745361,1261.142334 1148.513184,1261.391602 1148.091309,1261.811768 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1149.106689,1260.817383 
	C1149.095215,1260.681152 1149.270386,1260.370483 1149.707520,1259.967529 
	C1149.744019,1260.130981 1149.518799,1260.386963 1149.106689,1260.817383 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1150.075439,1259.808472 
	C1150.065552,1259.665405 1150.255127,1259.364258 1150.708252,1258.986816 
	C1150.739502,1259.157104 1150.507202,1259.403809 1150.075439,1259.808472 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1151.098389,1258.820068 
	C1151.092529,1258.686646 1151.282959,1258.389893 1151.727173,1257.997070 
	C1151.752075,1258.152710 1151.523438,1258.404663 1151.098389,1258.820068 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1117.172363,1292.802856 
	C1117.161743,1292.675415 1117.334351,1292.399414 1117.742065,1292.034668 
	C1117.796265,1292.204956 1117.588989,1292.441040 1117.172363,1292.802856 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1152.103516,1257.812256 
	C1152.088989,1257.669312 1152.268066,1257.369507 1152.706665,1256.994385 
	C1152.768066,1257.187012 1152.544922,1257.432495 1152.103516,1257.812256 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1153.104492,1256.814941 
	C1153.098999,1256.682007 1153.289795,1256.386108 1153.734375,1255.993652 
	C1153.759155,1256.148682 1153.530029,1256.400269 1153.104492,1256.814941 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1154.099609,1255.806641 
	C1154.082764,1255.662231 1154.258423,1255.352905 1154.699219,1254.962524 
	C1154.740479,1255.134888 1154.516357,1255.388184 1154.099609,1255.806641 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1155.071045,1254.816772 
	C1155.061646,1254.673706 1155.249878,1254.377686 1155.697998,1254.013184 
	C1155.754150,1254.209351 1155.524414,1254.449097 1155.071045,1254.816772 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1156.129150,1253.813232 
	C1156.120361,1253.683472 1156.297852,1253.405151 1156.714722,1253.042969 
	C1156.766968,1253.217651 1156.554077,1253.452881 1156.129150,1253.813232 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1157.136475,1252.807129 
	C1157.114380,1252.667847 1157.270508,1252.384888 1157.666870,1252.038086 
	C1157.739624,1252.229858 1157.542114,1252.459595 1157.136475,1252.807129 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1159.128662,1250.764160 
	C1159.111084,1250.621704 1159.284180,1250.330566 1159.713013,1249.966919 
	C1159.777466,1250.157593 1159.561035,1250.397949 1159.128662,1250.764160 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1160.094727,1249.819092 
	C1160.089233,1249.684570 1160.278442,1249.383301 1160.726318,1248.990479 
	C1160.753174,1249.150024 1160.521362,1249.401123 1160.094727,1249.819092 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1124.652710,1284.971436 
	C1124.921265,1285.087158 1124.939331,1285.269043 1124.968994,1285.724854 
	C1124.796875,1285.986572 1124.613281,1285.974487 1124.155762,1285.930786 
	C1124.055054,1285.611938 1124.228638,1285.324707 1124.652710,1284.971436 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1121.154419,1288.776367 
	C1121.161865,1288.630371 1121.399902,1288.294434 1121.966309,1287.859863 
	C1121.991333,1288.036377 1121.688232,1288.311401 1121.154419,1288.776367 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1120.144775,1289.817383 
	C1120.133057,1289.686035 1120.308105,1289.397339 1120.727295,1289.015137 
	C1120.782227,1289.188721 1120.568970,1289.434937 1120.144775,1289.817383 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1126.991699,1283.729248 
	C1126.819946,1283.994385 1126.640015,1283.989990 1126.190063,1283.963623 
	C1126.094360,1283.652710 1126.268555,1283.363770 1126.691406,1282.998047 
	C1126.954590,1283.100708 1126.968994,1283.280151 1126.991699,1283.729248 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1127.098267,1282.816284 
	C1127.090210,1282.679321 1127.277954,1282.378418 1127.725098,1281.988770 
	C1127.754395,1282.150757 1127.524170,1282.401489 1127.098267,1282.816284 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1119.122803,1290.823242 
	C1119.113037,1290.687378 1119.295654,1290.401733 1119.726440,1290.036865 
	C1119.781006,1290.220581 1119.561157,1290.459106 1119.122803,1290.823242 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1130.102783,1279.822754 
	C1130.095703,1279.688965 1130.282715,1279.393311 1130.722412,1279.005005 
	C1130.748901,1279.161865 1130.522827,1279.411255 1130.102783,1279.822754 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1131.112915,1278.809570 
	C1131.098267,1278.668335 1131.275757,1278.370605 1131.710205,1277.994995 
	C1131.771729,1278.185303 1131.551025,1278.430542 1131.112915,1278.809570 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1132.698120,1277.025391 
	C1132.954590,1277.121826 1132.968750,1277.297363 1132.987305,1277.737061 
	C1132.813843,1277.996460 1132.636108,1277.991577 1132.192017,1277.967407 
	C1132.102417,1277.666870 1132.279175,1277.385620 1132.698120,1277.025391 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1116.144531,1293.806152 
	C1116.128296,1293.666870 1116.295166,1293.385620 1116.707520,1293.044189 
	C1116.770752,1293.234741 1116.562378,1293.461426 1116.144531,1293.806152 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1118.139038,1291.823242 
	C1118.121338,1291.682129 1118.291748,1291.385742 1118.716064,1291.013184 
	C1118.782104,1291.203613 1118.567749,1291.447266 1118.139038,1291.823242 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1122.973511,1286.989868 
	C1122.970703,1286.840210 1123.180786,1286.471313 1123.687012,1285.985840 
	C1123.717407,1286.169556 1123.451782,1286.470093 1122.973511,1286.989868 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1200.092896,1209.809570 
	C1200.080566,1209.668579 1200.265869,1209.366943 1200.710571,1208.978516 
	C1200.743408,1209.144165 1200.516968,1209.396606 1200.092896,1209.809570 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1201.087158,1208.822266 
	C1201.078003,1208.677246 1201.267700,1208.385986 1201.717041,1208.035278 
	C1201.771729,1208.234131 1201.541504,1208.467529 1201.087158,1208.822266 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1202.161377,1207.825562 
	C1202.156128,1207.701538 1202.336792,1207.426758 1202.750732,1207.056152 
	C1202.797485,1207.221313 1202.585327,1207.459473 1202.161377,1207.825562 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1203.151489,1206.828125 
	C1203.130371,1206.685425 1203.294922,1206.385376 1203.715088,1206.010010 
	C1203.785278,1206.202148 1203.573975,1206.447632 1203.151489,1206.828125 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1204.121338,1205.812988 
	C1204.116333,1205.683960 1204.305908,1205.393311 1204.742188,1204.998779 
	C1204.764648,1205.146973 1204.540283,1205.399170 1204.121338,1205.812988 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1205.095459,1204.802490 
	C1205.078735,1204.655273 1205.255493,1204.345215 1205.702881,1203.963867 
	C1205.745361,1204.141602 1205.517212,1204.390503 1205.095459,1204.802490 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1206.065430,1203.820557 
	C1206.049683,1203.680420 1206.220581,1203.382080 1206.641846,1203.010254 
	C1206.709229,1203.205444 1206.495728,1203.447388 1206.065430,1203.820557 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1208.077637,1201.781128 
	C1208.063965,1201.634277 1208.249512,1201.330933 1208.703369,1200.955811 
	C1208.740112,1201.130737 1208.508423,1201.377686 1208.077637,1201.781128 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1209.072388,1200.818237 
	C1209.063354,1200.674072 1209.250610,1200.376587 1209.699951,1200.016846 
	C1209.756104,1200.216187 1209.525024,1200.452881 1209.072388,1200.818237 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1210.144165,1199.816650 
	C1210.137695,1199.685425 1210.320801,1199.409424 1210.746216,1199.057007 
	C1210.794800,1199.234253 1210.576538,1199.464600 1210.144165,1199.816650 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1212.147461,1197.794800 
	C1212.135376,1197.662842 1212.311157,1197.371338 1212.734863,1196.983643 
	C1212.788696,1197.155762 1212.572998,1197.405029 1212.147461,1197.794800 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1199.134888,1210.803711 
	C1199.120361,1210.667480 1199.296021,1210.371948 1199.722168,1209.983643 
	C1199.783081,1210.164429 1199.567139,1210.415649 1199.134888,1210.803711 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1178.100098,1231.823486 
	C1178.091187,1231.687012 1178.275391,1231.399048 1178.709473,1231.032715 
	C1178.763428,1231.218018 1178.541382,1231.457031 1178.100098,1231.823486 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1162.112061,1247.815674 
	C1162.099243,1247.676514 1162.275391,1247.367065 1162.717041,1246.973633 
	C1162.755249,1247.141602 1162.528076,1247.393555 1162.112061,1247.815674 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1163.088013,1246.808472 
	C1163.076294,1246.665039 1163.254395,1246.351318 1163.705688,1245.967529 
	C1163.745117,1246.144409 1163.511597,1246.391357 1163.088013,1246.808472 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1164.130615,1245.785645 
	C1164.149414,1245.640991 1164.403809,1245.293701 1165.008057,1244.863525 
	C1165.027466,1245.048096 1164.696899,1245.315552 1164.130615,1245.785645 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1165.973755,1243.972046 
	C1165.964600,1243.815308 1166.170288,1243.453003 1166.672607,1242.989746 
	C1166.708984,1243.181396 1166.448853,1243.473999 1165.973755,1243.972046 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1167.082275,1242.819580 
	C1167.077148,1242.683472 1167.269531,1242.386353 1167.720337,1242.003174 
	C1167.745728,1242.164307 1167.512817,1242.411499 1167.082275,1242.819580 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1168.108154,1241.812012 
	C1168.092407,1241.668579 1168.270020,1241.367432 1168.707275,1240.990479 
	C1168.771606,1241.184937 1168.549683,1241.431396 1168.108154,1241.812012 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1171.354004,1238.819580 
	C1171.061401,1238.615112 1171.153564,1238.332520 1171.718994,1238.041748 
	C1171.817993,1238.204346 1171.666870,1238.440308 1171.354004,1238.819580 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1172.134399,1237.790527 
	C1172.114624,1237.650269 1172.281982,1237.347046 1172.707520,1236.957520 
	C1172.773560,1237.142700 1172.558838,1237.394775 1172.134399,1237.790527 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1173.078003,1236.805176 
	C1173.066650,1236.661377 1173.250366,1236.351440 1173.703979,1235.967041 
	C1173.740234,1236.141357 1173.506714,1236.390259 1173.078003,1236.805176 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1174.079834,1235.814941 
	C1174.071289,1235.674683 1174.260254,1235.370728 1174.712158,1234.982910 
	C1174.742432,1235.149902 1174.510010,1235.400513 1174.079834,1235.814941 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1175.100098,1234.818848 
	C1175.089233,1234.680054 1175.271484,1234.381958 1175.710693,1234.002930 
	C1175.742798,1234.167847 1175.517944,1234.413696 1175.100098,1234.818848 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1176.108887,1233.812134 
	C1176.098633,1233.674683 1176.283203,1233.378418 1176.723877,1232.995605 
	C1176.754517,1233.156982 1176.529053,1233.405151 1176.108887,1233.812134 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1177.108643,1232.812500 
	C1177.093994,1232.670532 1177.271240,1232.369751 1177.707520,1231.992188 
	C1177.769531,1232.183838 1177.547363,1232.429932 1177.108643,1232.812500 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1161.091553,1248.810059 
	C1161.079102,1248.663940 1161.262817,1248.359863 1161.714111,1247.989868 
	C1161.750366,1248.166626 1161.519043,1248.409302 1161.091553,1248.810059 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1183.080566,1226.817139 
	C1183.071777,1226.677002 1183.260376,1226.380005 1183.707520,1226.004517 
	C1183.736572,1226.170898 1183.507324,1226.415527 1183.080566,1226.817139 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1184.111084,1225.821533 
	C1184.100464,1225.684448 1184.279541,1225.401489 1184.705566,1225.049561 
	C1184.761719,1225.237061 1184.544434,1225.468872 1184.111084,1225.821533 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1185.165771,1224.796631 
	C1185.154785,1224.668335 1185.328247,1224.383301 1185.742920,1224.001709 
	C1185.795288,1224.169312 1185.583984,1224.414307 1185.165771,1224.796631 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1188.091797,1221.810669 
	C1188.079346,1221.665771 1188.264160,1221.362671 1188.714600,1220.987305 
	C1188.749878,1221.160767 1188.519409,1221.406738 1188.091797,1221.810669 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1189.110229,1220.817871 
	C1189.097046,1220.677734 1189.274414,1220.375366 1189.711670,1219.993164 
	C1189.747925,1220.160767 1189.524292,1220.408081 1189.110229,1220.817871 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1190.097900,1219.820190 
	C1190.083374,1219.682983 1190.256836,1219.390259 1190.677490,1219.016357 
	C1190.740234,1219.203491 1190.527222,1219.446533 1190.097900,1219.820190 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1192.132324,1217.733887 
	C1192.118042,1217.595093 1192.294800,1217.313599 1192.722778,1216.958496 
	C1192.780762,1217.142334 1192.563843,1217.377686 1192.132324,1217.733887 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1193.091797,1216.814453 
	C1193.080566,1216.674072 1193.263184,1216.369385 1193.707764,1215.982178 
	C1193.741699,1216.149902 1193.513672,1216.399902 1193.091797,1216.814453 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1194.090820,1215.817139 
	C1194.081177,1215.677734 1194.267578,1215.383301 1194.709717,1215.010620 
	C1194.765259,1215.199951 1194.539062,1215.443237 1194.090820,1215.817139 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1195.114502,1214.852539 
	C1195.105957,1214.688232 1195.282104,1214.415527 1195.707397,1214.170654 
	C1195.757446,1214.404785 1195.538452,1214.586670 1195.114502,1214.852539 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1197.350586,1212.826904 
	C1197.042114,1212.612061 1197.134033,1212.324707 1197.722656,1212.048584 
	C1197.826904,1212.224121 1197.670776,1212.455566 1197.350586,1212.826904 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1198.158447,1211.810303 
	C1198.143921,1211.677734 1198.312500,1211.393555 1198.722900,1211.025269 
	C1198.782837,1211.202881 1198.575073,1211.441895 1198.158447,1211.810303 
z"
      />
      <path
        fill="#8A0C00"
        opacity="1.000000"
        stroke="none"
        d="
M841.092102,1122.999512 
	C841.207642,1093.122314 841.433594,1063.244873 841.407959,1033.367798 
	C841.381897,1003.003418 841.085388,972.639404 840.955627,942.274963 
	C840.915894,932.988586 840.997314,923.701233 841.093811,914.414978 
	C841.173035,906.788086 841.219421,906.788574 849.852600,905.197021 
	C851.337036,905.565002 852.168518,905.781311 852.964478,906.404785 
	C851.953186,912.209106 850.977417,917.606140 849.768372,923.242554 
	C849.006653,924.121887 848.534119,924.819580 847.941284,925.392944 
	C842.163025,930.981445 842.261536,930.953613 846.456726,938.130249 
	C847.473450,939.869629 847.817627,942.161438 847.945007,944.231567 
	C848.334045,950.560303 850.110107,952.210205 857.661011,952.126526 
	C856.516785,949.399353 854.759521,946.921387 854.596802,944.342773 
	C854.433716,941.758972 855.862366,939.074768 856.719116,935.980469 
	C858.883423,937.505554 859.940491,938.250427 861.023926,939.318726 
	C861.368530,940.428040 861.686707,941.214050 862.089722,942.397949 
	C862.451416,945.863953 862.728149,948.932007 863.005371,952.430542 
	C863.825806,955.141296 864.107605,958.888672 865.567505,959.424133 
	C869.791077,960.973389 874.488586,961.230652 879.275635,961.827759 
	C880.700867,961.105347 881.850464,960.550232 883.402893,959.995117 
	C886.204651,960.996826 888.603516,961.998413 890.990540,963.400635 
	C890.742432,965.277161 889.809204,967.180298 890.402344,968.147888 
	C892.093750,970.907471 894.005859,974.757690 896.572144,975.456055 
	C900.067078,976.407043 898.942932,972.436890 899.342163,970.115845 
	C899.635681,968.409058 900.907776,966.066589 902.294922,965.580444 
	C903.653076,965.104553 906.179382,966.152344 907.369385,967.349731 
	C910.793091,970.794678 910.300232,971.974243 903.522095,976.309143 
	C906.004944,978.081970 908.130188,979.475586 910.111145,981.050781 
	C911.882996,982.459717 914.172241,983.758179 915.042053,985.652283 
	C917.042969,990.009827 918.480469,990.820923 921.799316,987.399841 
	C924.726074,984.382874 927.052979,985.382690 929.555481,987.434509 
	C932.849548,990.135498 936.066284,992.933594 939.258667,995.755127 
	C943.343750,999.365662 946.302795,998.736816 948.127502,993.795410 
	C949.615479,989.765808 951.535950,989.348694 954.581299,992.393799 
	C957.494263,995.306580 960.017151,995.009399 962.062317,991.717529 
	C964.088684,988.455872 965.774963,984.667786 968.608154,982.308777 
	C971.589600,979.826233 975.611511,978.075928 979.442749,977.287170 
	C982.796082,976.596741 984.490723,979.056702 983.510071,982.747009 
	C982.032532,988.306641 983.386047,990.254150 989.169617,991.166687 
	C992.181946,984.077271 995.198364,976.978210 998.604309,968.962402 
	C1000.169922,969.955933 1001.869751,970.943665 1003.467834,972.074890 
	C1004.988281,973.151306 1006.402466,974.377747 1007.863403,975.538208 
	C1008.856445,973.926575 1010.159424,972.424744 1010.776978,970.680176 
	C1011.998230,967.230103 1013.626648,964.063904 1017.789734,965.668396 
	C1021.227722,966.993347 1023.531677,965.831177 1026.238037,964.261597 
	C1026.870850,965.912964 1028.063965,967.896362 1027.540894,968.595581 
	C1024.306152,972.918945 1024.871460,977.621948 1025.335449,982.442200 
	C1025.410767,983.224731 1024.689575,984.403015 1023.985107,984.888367 
	C1020.202698,987.493835 1021.005554,990.051392 1023.354553,993.274902 
	C1025.991455,996.893616 1025.462891,1007.595398 1023.425415,1009.918274 
	C1021.606262,1011.992371 1020.374268,1014.583679 1018.899780,1016.957031 
	C1017.963928,1018.463379 1017.364014,1020.343140 1016.086670,1021.444458 
	C1010.392151,1026.354248 1010.323608,1026.274780 1009.073364,1031.093140 
	C1005.556396,1031.547974 1002.474976,1031.537842 999.658569,1032.396851 
	C993.983459,1034.127441 988.083740,1035.682983 982.997742,1038.577637 
	C979.847229,1040.370850 975.572266,1045.066162 976.128296,1047.449585 
	C977.191406,1052.007446 974.908203,1055.192139 974.186523,1058.896606 
	C973.956848,1060.075439 971.348755,1061.378174 969.701355,1061.579590 
	C965.327698,1062.114136 964.255920,1063.819092 966.270142,1067.738647 
	C967.400574,1069.938477 968.434509,1072.224609 969.180664,1074.577026 
	C970.610840,1079.085571 964.503357,1087.784424 959.983582,1087.370728 
	C956.772400,1087.076782 955.553528,1088.439087 955.422852,1091.117310 
	C955.272522,1094.199951 957.270264,1094.595337 960.006348,1094.883667 
	C963.888062,1095.292847 967.752991,1096.390991 971.443970,1097.703735 
	C972.354126,1098.027466 972.914124,1100.296631 972.999023,1101.709595 
	C973.084106,1103.127075 972.374817,1104.592163 971.855957,1106.208130 
	C971.475281,1106.597778 971.241699,1106.803589 970.627502,1107.062744 
	C956.030579,1111.962402 941.796692,1116.776123 927.588196,1121.663818 
	C926.222595,1122.133667 925.015015,1123.062866 923.339966,1123.802734 
	C917.299255,1125.608521 911.652527,1127.390503 905.639099,1129.213623 
	C899.182434,1131.176270 893.092468,1133.097534 886.686279,1134.858398 
	C873.881531,1130.625854 861.435425,1126.413452 848.866272,1122.607666 
	C846.528748,1121.899780 843.696228,1122.827026 841.092102,1122.999512 
z"
      />
      <path
        fill="#921100"
        opacity="1.000000"
        stroke="none"
        d="
M972.013672,1106.036377 
	C972.374817,1104.592163 973.084106,1103.127075 972.999023,1101.709595 
	C972.914124,1100.296631 972.354126,1098.027466 971.443970,1097.703735 
	C967.752991,1096.390991 963.888062,1095.292847 960.006348,1094.883667 
	C957.270264,1094.595337 955.272522,1094.199951 955.422852,1091.117310 
	C955.553528,1088.439087 956.772400,1087.076782 959.983582,1087.370728 
	C964.503357,1087.784424 970.610840,1079.085571 969.180664,1074.577026 
	C968.434509,1072.224609 967.400574,1069.938477 966.270142,1067.738647 
	C964.255920,1063.819092 965.327698,1062.114136 969.701355,1061.579590 
	C971.348755,1061.378174 973.956848,1060.075439 974.186523,1058.896606 
	C974.908203,1055.192139 977.191406,1052.007446 976.128296,1047.449585 
	C975.572266,1045.066162 979.847229,1040.370850 982.997742,1038.577637 
	C988.083740,1035.682983 993.983459,1034.127441 999.658569,1032.396851 
	C1002.474976,1031.537842 1005.556396,1031.547974 1009.073364,1031.093140 
	C1010.323608,1026.274780 1010.392151,1026.354248 1016.086670,1021.444458 
	C1017.364014,1020.343140 1017.963928,1018.463379 1018.899780,1016.957031 
	C1020.374268,1014.583679 1021.606262,1011.992371 1023.425415,1009.918274 
	C1025.462891,1007.595398 1025.991455,996.893616 1023.354553,993.274902 
	C1021.005554,990.051392 1020.202698,987.493835 1023.985107,984.888367 
	C1024.689575,984.403015 1025.410767,983.224731 1025.335449,982.442200 
	C1024.871460,977.621948 1024.306152,972.918945 1027.540894,968.595581 
	C1028.063965,967.896362 1026.870850,965.912964 1026.237549,963.927856 
	C1026.003418,962.887634 1026.002563,962.442627 1026.247681,961.745972 
	C1028.662354,960.662842 1030.831177,959.831421 1033.086182,959.373047 
	C1033.820435,962.787476 1034.262695,965.895020 1035.209229,968.840454 
	C1035.641724,970.186218 1037.068115,971.212524 1038.043823,972.383728 
	C1039.151733,971.334961 1040.779785,970.486755 1041.275391,969.201782 
	C1043.169678,964.290161 1038.895630,959.175049 1032.911865,958.596375 
	C1032.551758,955.557861 1031.669556,952.796875 1032.180420,950.323669 
	C1032.687378,947.870056 1034.056763,944.709595 1036.017090,943.646667 
	C1039.033691,942.011169 1042.373535,943.273315 1044.760742,946.657227 
	C1043.924561,947.246155 1043.086304,947.566650 1042.685425,948.175171 
	C1041.858643,949.430176 1041.265137,950.838806 1040.575684,952.184265 
	C1041.851807,952.774597 1043.081055,953.547180 1044.420410,953.891602 
	C1045.325806,954.124451 1046.407471,953.850464 1047.373413,953.636658 
	C1050.791748,952.879700 1051.883667,954.056213 1051.424438,957.588867 
	C1050.792114,962.454163 1052.288330,963.778503 1056.603027,961.820129 
	C1060.590576,960.010315 1063.775757,960.734253 1067.429443,962.525146 
	C1069.009033,963.299316 1071.685059,963.117798 1073.311401,962.288635 
	C1079.601440,959.081848 1080.389771,959.268982 1083.446411,965.570557 
	C1084.029053,966.771667 1086.044189,968.521423 1086.637939,968.263977 
	C1089.626831,966.968201 1093.150513,965.695312 1095.006348,963.278076 
	C1097.861206,959.559570 1100.266602,960.099915 1104.002930,961.415344 
	C1106.594360,962.327759 1111.263184,962.591797 1112.494263,961.089355 
	C1115.654175,957.232910 1119.111084,956.452332 1123.611084,955.014465 
	C1128.510132,953.449158 1133.488037,951.240295 1138.600342,949.526367 
	C1141.493530,948.556396 1143.402710,944.651367 1146.105713,941.683411 
	C1146.412964,941.915344 1147.352905,942.471130 1148.102417,943.218018 
	C1151.195801,946.300415 1155.029419,946.812073 1157.487305,943.540771 
	C1159.255493,941.187561 1159.653198,937.806702 1160.671997,934.887573 
	C1161.066772,933.755981 1161.488281,932.633789 1161.897705,931.507263 
	C1162.956177,932.175720 1164.245972,932.647095 1165.020996,933.557007 
	C1166.316406,935.077454 1167.282837,936.878113 1168.431396,938.627441 
	C1169.072998,938.028931 1169.533203,937.779541 1169.508179,937.596863 
	C1168.755127,932.116943 1166.275635,926.446106 1171.430908,921.661194 
	C1171.580688,921.522095 1171.262695,920.955566 1171.390503,920.703857 
	C1172.504395,918.509460 1173.118164,915.473328 1174.922729,914.325134 
	C1180.768799,910.605652 1183.051025,905.435852 1183.022705,898.852051 
	C1183.194458,898.903137 1183.366089,898.954285 1184.004883,899.234741 
	C1184.953979,899.649414 1185.435913,899.834778 1185.917847,900.020081 
	C1192.514160,902.124207 1199.190430,904.019409 1205.645264,906.492981 
	C1207.093750,907.048096 1208.495972,909.557739 1208.658569,911.286194 
	C1209.120483,916.197449 1208.930664,921.169922 1208.707520,926.839905 
	C1205.975708,931.091187 1204.162354,934.689026 1206.215454,939.139771 
	C1206.655396,940.093628 1206.427368,941.403442 1206.353271,942.539246 
	C1205.588379,954.270996 1204.759155,965.998718 1204.017334,977.731873 
	C1203.597900,984.366089 1203.348145,991.011169 1202.914307,997.644226 
	C1202.850464,998.617920 1202.188599,999.552490 1201.810425,1000.488159 
	C1201.019531,1000.210571 1200.601074,1000.196228 1200.422729,999.981934 
	C1195.438232,993.995178 1195.446289,993.994141 1191.621216,1000.517700 
	C1191.200684,1001.235046 1190.734863,1001.931396 1190.233887,1002.595093 
	C1186.980713,1006.905090 1180.430664,1008.119141 1176.560181,1005.333374 
	C1175.353760,1004.465210 1173.733887,1004.171692 1172.303223,1003.615112 
	C1171.875977,1005.137329 1171.284302,1006.637512 1171.076660,1008.189087 
	C1170.927002,1009.307007 1171.240112,1010.501526 1171.430786,1011.647888 
	C1172.507202,1018.120544 1168.137207,1022.418823 1161.863037,1020.454163 
	C1158.917969,1019.531982 1157.291626,1019.932129 1155.451538,1022.177856 
	C1154.513184,1023.323059 1152.534180,1024.819092 1151.612671,1024.493652 
	C1150.214355,1024.000122 1148.864868,1022.226990 1148.307617,1020.700623 
	C1146.450684,1015.612854 1145.641113,1015.572144 1141.095093,1018.303284 
	C1135.520386,1021.652405 1129.660522,1024.678833 1123.609985,1027.040039 
	C1117.705688,1029.344360 1110.900757,1028.663818 1105.730957,1033.810181 
	C1102.929077,1036.599487 1099.646118,1034.297729 1098.399048,1030.836426 
	C1097.635864,1028.718140 1097.789917,1026.273682 1097.498657,1023.979919 
	C1097.250000,1022.022644 1097.128174,1020.021301 1096.589111,1018.143188 
	C1096.384399,1017.430542 1095.223755,1016.711304 1094.395386,1016.559509 
	C1093.861572,1016.461731 1092.555542,1017.441895 1092.591431,1017.851807 
	C1092.978149,1022.270508 1088.713867,1026.139038 1092.014404,1031.160156 
	C1094.836670,1035.453613 1088.732544,1035.830078 1087.179443,1038.352905 
	C1085.474609,1037.288940 1083.364258,1036.548340 1082.182739,1035.066895 
	C1081.077637,1033.681274 1080.957520,1031.510132 1080.403931,1029.684692 
	C1079.872314,1029.604370 1079.340698,1029.524048 1078.809082,1029.443726 
	C1077.240601,1033.373901 1075.672241,1037.304199 1073.995361,1041.505981 
	C1069.438599,1038.934937 1065.514404,1036.460083 1067.682007,1029.727417 
	C1063.670898,1031.791870 1060.665161,1034.520996 1057.220215,1036.476807 
	C1053.812622,1038.411499 1049.239990,1041.572876 1046.478394,1040.579956 
	C1042.564453,1039.172729 1037.725952,1036.955200 1036.336182,1031.298218 
	C1035.218994,1026.751221 1031.550293,1026.324341 1028.644775,1030.039673 
	C1027.526855,1031.469116 1026.817139,1033.558716 1026.734985,1035.384155 
	C1026.576294,1038.912109 1027.195801,1042.470947 1027.105957,1046.007202 
	C1027.042480,1048.501465 1026.342407,1050.979736 1025.924072,1053.464844 
	C1025.543091,1055.728516 1025.219849,1058.004028 1024.760376,1060.251709 
	C1024.238037,1062.806763 1022.993286,1065.365845 1023.116516,1067.871460 
	C1023.448120,1074.614868 1024.340820,1081.330688 1024.653076,1088.138916 
	C1012.372681,1092.370483 1000.461853,1096.555176 988.521912,1100.655151 
	C983.048584,1102.534668 977.518250,1104.247803 972.013672,1106.036377 
z"
      />
      <path
        fill="#9C1200"
        opacity="1.000000"
        stroke="none"
        d="
M1025.010742,1088.057373 
	C1024.340820,1081.330688 1023.448120,1074.614868 1023.116516,1067.871460 
	C1022.993286,1065.365845 1024.238037,1062.806763 1024.760376,1060.251709 
	C1025.219849,1058.004028 1025.543091,1055.728516 1025.924072,1053.464844 
	C1026.342407,1050.979736 1027.042480,1048.501465 1027.105957,1046.007202 
	C1027.195801,1042.470947 1026.576294,1038.912109 1026.734985,1035.384155 
	C1026.817139,1033.558716 1027.526855,1031.469116 1028.644775,1030.039673 
	C1031.550293,1026.324341 1035.218994,1026.751221 1036.336182,1031.298218 
	C1037.725952,1036.955200 1042.564453,1039.172729 1046.478394,1040.579956 
	C1049.239990,1041.572876 1053.812622,1038.411499 1057.220215,1036.476807 
	C1060.665161,1034.520996 1063.670898,1031.791870 1067.682007,1029.727417 
	C1065.514404,1036.460083 1069.438599,1038.934937 1073.995361,1041.505981 
	C1075.672241,1037.304199 1077.240601,1033.373901 1078.809082,1029.443726 
	C1079.340698,1029.524048 1079.872314,1029.604370 1080.403931,1029.684692 
	C1080.957520,1031.510132 1081.077637,1033.681274 1082.182739,1035.066895 
	C1083.364258,1036.548340 1085.474609,1037.288940 1087.179443,1038.352905 
	C1088.732544,1035.830078 1094.836670,1035.453613 1092.014404,1031.160156 
	C1088.713867,1026.139038 1092.978149,1022.270508 1092.591431,1017.851807 
	C1092.555542,1017.441895 1093.861572,1016.461731 1094.395386,1016.559509 
	C1095.223755,1016.711304 1096.384399,1017.430542 1096.589111,1018.143188 
	C1097.128174,1020.021301 1097.250000,1022.022644 1097.498657,1023.979919 
	C1097.789917,1026.273682 1097.635864,1028.718140 1098.399048,1030.836426 
	C1099.646118,1034.297729 1102.929077,1036.599487 1105.730957,1033.810181 
	C1110.900757,1028.663818 1117.705688,1029.344360 1123.609985,1027.040039 
	C1129.660522,1024.678833 1135.520386,1021.652405 1141.095093,1018.303284 
	C1145.641113,1015.572144 1146.450684,1015.612854 1148.307617,1020.700623 
	C1148.864868,1022.226990 1150.214355,1024.000122 1151.612671,1024.493652 
	C1152.534180,1024.819092 1154.513184,1023.323059 1155.451538,1022.177856 
	C1157.291626,1019.932129 1158.917969,1019.531982 1161.863037,1020.454163 
	C1168.137207,1022.418823 1172.507202,1018.120544 1171.430786,1011.647888 
	C1171.240112,1010.501526 1170.927002,1009.307007 1171.076660,1008.189087 
	C1171.284302,1006.637512 1171.875977,1005.137329 1172.303223,1003.615112 
	C1173.733887,1004.171692 1175.353760,1004.465210 1176.560181,1005.333374 
	C1180.430664,1008.119141 1186.980713,1006.905090 1190.233887,1002.595093 
	C1190.734863,1001.931396 1191.200684,1001.235046 1191.621216,1000.517700 
	C1195.446289,993.994141 1195.438232,993.995178 1200.422729,999.981934 
	C1200.601074,1000.196228 1201.019531,1000.210571 1201.810425,1000.488159 
	C1202.188599,999.552490 1202.850464,998.617920 1202.914307,997.644226 
	C1203.348145,991.011169 1203.597900,984.366089 1204.017334,977.731873 
	C1204.759155,965.998718 1205.588379,954.270996 1206.353271,942.539246 
	C1206.427368,941.403442 1206.655396,940.093628 1206.215454,939.139771 
	C1204.162354,934.689026 1205.975708,931.091187 1208.644287,927.308044 
	C1208.767456,934.554993 1208.550049,942.055786 1208.583618,949.555542 
	C1208.657593,966.118713 1208.954590,982.681091 1208.984985,999.244141 
	C1209.036621,1027.528442 1208.953369,1055.813110 1208.523804,1084.613037 
	C1207.111206,1086.146729 1206.240601,1087.384888 1205.058960,1088.130493 
	C1203.342407,1089.213623 1201.475586,1090.187866 1199.540771,1090.768677 
	C1195.700562,1091.921509 1191.654785,1092.462646 1187.914185,1093.850342 
	C1185.002686,1094.930664 1182.594604,1097.417603 1179.667358,1098.396362 
	C1170.954956,1101.309937 1162.109985,1103.832764 1153.291992,1106.423340 
	C1144.380249,1109.041748 1135.346191,1111.286987 1126.563354,1114.276245 
	C1123.721069,1115.243530 1121.503296,1118.045410 1118.622070,1119.966553 
	C1101.163208,1114.295288 1084.082153,1108.665527 1066.664795,1102.875610 
	C1062.891479,1101.780396 1059.454468,1100.845459 1055.737915,1099.702637 
	C1045.309082,1095.682373 1035.159912,1091.869873 1025.010742,1088.057373 
M1073.742432,1051.848999 
	C1073.897461,1051.961060 1074.052490,1052.073120 1074.207520,1052.185303 
	C1074.109863,1052.021484 1074.012085,1051.857666 1073.742432,1051.848999 
z"
      />
      <path
        fill="#7A0A00"
        opacity="1.000000"
        stroke="none"
        d="
M862.004883,942.000000 
	C861.686707,941.214050 861.368530,940.428040 861.037048,938.918823 
	C861.268372,936.388855 861.886719,934.515991 861.583740,932.806335 
	C861.452454,932.065430 858.434265,931.111816 858.395325,931.181763 
	C854.829712,937.597412 849.859070,931.178833 845.594177,932.892273 
	C846.198242,930.824219 847.379211,929.589111 848.131775,928.132568 
	C848.960388,926.528931 849.395264,924.721741 850.001709,923.003235 
	C850.977417,917.606140 851.953186,912.209106 852.964478,906.074280 
	C853.001160,904.895813 853.002136,904.455139 853.003113,904.014404 
	C855.411011,903.013428 857.818848,902.012451 860.681396,901.190430 
	C861.775269,901.204163 862.414368,901.038940 863.053467,900.873657 
	C878.540039,895.265503 893.968994,889.491638 909.525635,884.085022 
	C938.575439,873.988770 967.700378,864.108704 996.791809,854.132263 
	C1005.377258,851.187927 1013.938843,848.172302 1022.556030,845.324524 
	C1023.725464,844.938049 1025.156372,845.342651 1026.633911,845.914917 
	C1023.934204,853.341675 1023.934204,853.341675 1029.621826,857.777649 
	C1029.369751,858.327393 1029.248169,859.078918 1028.807495,859.477722 
	C1024.509888,863.366516 1023.640198,867.263123 1027.966431,871.895569 
	C1029.595947,873.640381 1029.362061,875.739685 1026.592041,877.111572 
	C1025.588135,877.608643 1024.792603,879.568481 1024.783569,880.866943 
	C1024.716309,890.517639 1025.027466,900.172424 1024.865479,909.820007 
	C1024.813110,912.935425 1023.643738,916.023193 1023.095093,919.142944 
	C1022.806091,920.786499 1022.087646,922.809448 1022.727295,924.070557 
	C1024.250977,927.074707 1023.447144,928.068359 1020.473877,929.059570 
	C1018.130493,929.840820 1016.168213,931.713745 1013.964294,932.975525 
	C1009.187744,935.710083 1006.902405,942.155273 1000.477783,942.302612 
	C997.736023,942.365479 994.869629,941.045593 992.254822,941.493164 
	C989.419861,941.978271 986.658081,943.566162 984.243896,945.225342 
	C983.797607,945.532104 985.063171,948.498840 985.702576,950.175171 
	C986.590210,952.502686 989.059570,955.777161 988.303467,956.849365 
	C986.858704,958.898499 983.730835,960.354248 981.087402,960.807373 
	C978.105469,961.318665 977.058411,962.407471 975.466431,965.025330 
	C973.965210,967.493958 969.719299,969.034668 966.475586,969.476196 
	C963.704773,969.853455 962.275635,967.417542 962.546387,963.959167 
	C962.844849,960.148010 959.147034,960.309387 957.950195,962.099487 
	C954.841919,966.748779 951.173584,969.667114 945.496582,970.025513 
	C940.334595,970.351440 935.090210,971.651306 930.085815,970.987183 
	C928.274475,970.746826 927.061157,966.052307 925.552185,963.403198 
	C925.397766,963.132141 925.085876,962.950928 924.847046,962.727966 
	C924.781677,962.971436 924.716370,963.214905 924.651001,963.458374 
	C919.429993,961.996216 914.208984,960.534119 908.471375,958.927307 
	C909.114746,960.898926 909.658875,962.566223 910.352722,964.692505 
	C903.558044,963.550171 897.661438,957.977051 891.002441,963.000000 
	C888.603516,961.998413 886.204651,960.996826 883.320435,959.687256 
	C882.128296,958.827637 881.421509,958.275940 880.714722,957.724304 
	C880.143188,959.147949 879.571594,960.571533 879.000000,961.995117 
	C874.488586,961.230652 869.791077,960.973389 865.567505,959.424133 
	C864.107605,958.888672 863.825806,955.141296 863.341309,952.335449 
	C864.952026,949.212708 866.227234,946.615662 867.502502,944.018616 
	C865.669983,943.345764 863.837402,942.672852 862.004883,942.000000 
M855.412964,927.862610 
	C855.147278,927.405090 854.881653,926.947571 854.615906,926.489990 
	C854.318665,926.774902 854.021484,927.059875 853.724304,927.344849 
	C854.178101,927.624695 854.631897,927.904602 855.412964,927.862610 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M890.990540,963.400635 
	C897.661438,957.977051 903.558044,963.550171 910.352722,964.692505 
	C909.658875,962.566223 909.114746,960.898926 908.471375,958.927307 
	C914.208984,960.534119 919.429993,961.996216 924.651001,963.458374 
	C924.716370,963.214905 924.781677,962.971436 924.847046,962.727966 
	C925.085876,962.950928 925.397766,963.132141 925.552185,963.403198 
	C927.061157,966.052307 928.274475,970.746826 930.085815,970.987183 
	C935.090210,971.651306 940.334595,970.351440 945.496582,970.025513 
	C951.173584,969.667114 954.841919,966.748779 957.950195,962.099487 
	C959.147034,960.309387 962.844849,960.148010 962.546387,963.959167 
	C962.275635,967.417542 963.704773,969.853455 966.475586,969.476196 
	C969.719299,969.034668 973.965210,967.493958 975.466431,965.025330 
	C977.058411,962.407471 978.105469,961.318665 981.087402,960.807373 
	C983.730835,960.354248 986.858704,958.898499 988.303467,956.849365 
	C989.059570,955.777161 986.590210,952.502686 985.702576,950.175171 
	C985.063171,948.498840 983.797607,945.532104 984.243896,945.225342 
	C986.658081,943.566162 989.419861,941.978271 992.254822,941.493164 
	C994.869629,941.045593 997.736023,942.365479 1000.477783,942.302612 
	C1006.902405,942.155273 1009.187744,935.710083 1013.964294,932.975525 
	C1016.168213,931.713745 1018.130493,929.840820 1020.473877,929.059570 
	C1023.447144,928.068359 1024.250977,927.074707 1022.727295,924.070557 
	C1022.087646,922.809448 1022.806091,920.786499 1023.095093,919.142944 
	C1023.643738,916.023193 1024.813110,912.935425 1024.865479,909.820007 
	C1025.027466,900.172424 1024.716309,890.517639 1024.783569,880.866943 
	C1024.792603,879.568481 1025.588135,877.608643 1026.592041,877.111572 
	C1029.362061,875.739685 1029.595947,873.640381 1027.966431,871.895569 
	C1023.640198,867.263123 1024.509888,863.366516 1028.807495,859.477722 
	C1029.248169,859.078918 1029.369751,858.327393 1029.621826,857.777649 
	C1023.934204,853.341675 1023.934204,853.341675 1026.953979,846.106445 
	C1040.117310,850.072205 1053.137085,854.354370 1066.137207,858.695312 
	C1082.440186,864.139160 1098.760498,869.533691 1115.010864,875.131104 
	C1129.833984,880.236755 1144.573730,885.584839 1159.714233,891.355835 
	C1160.265381,892.521179 1160.263550,893.333923 1160.668213,893.765930 
	C1166.426636,899.913025 1161.546997,905.836975 1160.040283,911.690796 
	C1159.819824,912.546997 1156.537964,912.934326 1154.660156,912.975647 
	C1151.174561,913.052429 1147.658203,912.454956 1144.205078,912.742920 
	C1143.407837,912.809387 1142.043823,915.451355 1142.259766,916.675598 
	C1143.206055,922.042480 1142.082520,923.901917 1136.806030,925.049255 
	C1132.305176,926.027893 1127.650635,926.721436 1125.266724,931.727844 
	C1124.807495,932.692139 1122.493408,933.097656 1121.000854,933.187012 
	C1115.581909,933.511414 1110.636719,929.882385 1105.020142,931.248474 
	C1101.043823,932.215515 1096.633911,932.180969 1093.101074,933.954773 
	C1090.006104,935.508789 1088.028931,935.511780 1084.912964,934.243652 
	C1080.748413,932.548645 1076.117676,931.918945 1071.636230,931.116577 
	C1069.433105,930.722229 1066.681763,931.749268 1064.931519,930.777283 
	C1059.885010,927.975098 1055.112183,926.016602 1049.604492,929.453125 
	C1048.873657,929.909180 1047.643188,929.482788 1046.685913,929.674377 
	C1044.548340,930.102173 1042.138916,930.188110 1040.390137,931.287659 
	C1038.173828,932.681091 1036.823853,932.603027 1035.390137,930.547485 
	C1034.584961,929.392944 1033.683350,928.305542 1032.825317,927.187927 
	C1032.250366,927.146912 1031.675415,927.105957 1031.100464,927.064941 
	C1029.627319,931.104248 1027.333496,935.050049 1026.860352,939.203308 
	C1026.002319,946.736328 1026.228394,954.392761 1026.001953,961.997681 
	C1026.002563,962.442627 1026.003418,962.887634 1026.004517,963.666260 
	C1023.531677,965.831177 1021.227722,966.993347 1017.789734,965.668396 
	C1013.626648,964.063904 1011.998230,967.230103 1010.776978,970.680176 
	C1010.159424,972.424744 1008.856445,973.926575 1007.863403,975.538208 
	C1006.402466,974.377747 1004.988281,973.151306 1003.467834,972.074890 
	C1001.869751,970.943665 1000.169922,969.955933 998.604309,968.962402 
	C995.198364,976.978210 992.181946,984.077271 989.169617,991.166687 
	C983.386047,990.254150 982.032532,988.306641 983.510071,982.747009 
	C984.490723,979.056702 982.796082,976.596741 979.442749,977.287170 
	C975.611511,978.075928 971.589600,979.826233 968.608154,982.308777 
	C965.774963,984.667786 964.088684,988.455872 962.062317,991.717529 
	C960.017151,995.009399 957.494263,995.306580 954.581299,992.393799 
	C951.535950,989.348694 949.615479,989.765808 948.127502,993.795410 
	C946.302795,998.736816 943.343750,999.365662 939.258667,995.755127 
	C936.066284,992.933594 932.849548,990.135498 929.555481,987.434509 
	C927.052979,985.382690 924.726074,984.382874 921.799316,987.399841 
	C918.480469,990.820923 917.042969,990.009827 915.042053,985.652283 
	C914.172241,983.758179 911.882996,982.459717 910.111145,981.050781 
	C908.130188,979.475586 906.004944,978.081970 903.522095,976.309143 
	C910.300232,971.974243 910.793091,970.794678 907.369385,967.349731 
	C906.179382,966.152344 903.653076,965.104553 902.294922,965.580444 
	C900.907776,966.066589 899.635681,968.409058 899.342163,970.115845 
	C898.942932,972.436890 900.067078,976.407043 896.572144,975.456055 
	C894.005859,974.757690 892.093750,970.907471 890.402344,968.147888 
	C889.809204,967.180298 890.742432,965.277161 890.990540,963.400635 
M930.081543,984.289246 
	C930.048645,984.101562 930.015747,983.913940 929.982910,983.726257 
	C929.937744,983.910889 929.892639,984.095459 930.081543,984.289246 
z"
      />
      <path
        fill="#C23C04"
        opacity="1.000000"
        stroke="none"
        d="
M1067.001099,1103.035645 
	C1084.082153,1108.665527 1101.163208,1114.295288 1118.907593,1120.178223 
	C1137.524780,1126.615479 1155.484741,1132.782227 1173.430664,1138.989624 
	C1184.906616,1142.959351 1196.361938,1146.989136 1207.826538,1150.991821 
	C1200.402832,1153.729126 1193.016479,1156.573486 1185.547852,1159.182373 
	C1168.137451,1165.264282 1150.685547,1171.227295 1133.254150,1177.248779 
	C1119.071045,1182.148438 1104.893188,1187.063232 1089.984131,1191.917969 
	C1088.756226,1190.539673 1087.875000,1189.227051 1087.830811,1187.886963 
	C1087.706543,1184.121582 1088.918579,1180.011353 1087.787598,1176.647705 
	C1085.927246,1171.115234 1082.378540,1166.233032 1083.691895,1159.805298 
	C1084.020630,1158.196167 1082.278442,1156.189819 1081.566284,1154.335938 
	C1080.679321,1152.027344 1079.744507,1149.725098 1079.056885,1147.354370 
	C1077.077637,1140.530518 1075.256348,1133.660522 1073.256714,1126.842896 
	C1072.944458,1125.778687 1071.945923,1124.934937 1071.397583,1123.917725 
	C1068.275024,1118.126099 1065.192749,1112.312988 1062.193359,1106.688354 
	C1064.233398,1105.138428 1065.617310,1104.087036 1067.001099,1103.035645 
z"
      />
      <path
        fill="#8A0C00"
        opacity="1.000000"
        stroke="none"
        d="
M1026.247803,961.745972 
	C1026.228394,954.392761 1026.002319,946.736328 1026.860352,939.203308 
	C1027.333496,935.050049 1029.627319,931.104248 1031.100464,927.064941 
	C1031.675415,927.105957 1032.250366,927.146912 1032.825317,927.187927 
	C1033.683350,928.305542 1034.584961,929.392944 1035.390137,930.547485 
	C1036.823853,932.603027 1038.173828,932.681091 1040.390137,931.287659 
	C1042.138916,930.188110 1044.548340,930.102173 1046.685913,929.674377 
	C1047.643188,929.482788 1048.873657,929.909180 1049.604492,929.453125 
	C1055.112183,926.016602 1059.885010,927.975098 1064.931519,930.777283 
	C1066.681763,931.749268 1069.433105,930.722229 1071.636230,931.116577 
	C1076.117676,931.918945 1080.748413,932.548645 1084.912964,934.243652 
	C1088.028931,935.511780 1090.006104,935.508789 1093.101074,933.954773 
	C1096.633911,932.180969 1101.043823,932.215515 1105.020142,931.248474 
	C1110.636719,929.882385 1115.581909,933.511414 1121.000854,933.187012 
	C1122.493408,933.097656 1124.807495,932.692139 1125.266724,931.727844 
	C1127.650635,926.721436 1132.305176,926.027893 1136.806030,925.049255 
	C1142.082520,923.901917 1143.206055,922.042480 1142.259766,916.675598 
	C1142.043823,915.451355 1143.407837,912.809387 1144.205078,912.742920 
	C1147.658203,912.454956 1151.174561,913.052429 1154.660156,912.975647 
	C1156.537964,912.934326 1159.819824,912.546997 1160.040283,911.690796 
	C1161.546997,905.836975 1166.426636,899.913025 1160.668213,893.765930 
	C1160.263550,893.333923 1160.265381,892.521179 1160.043335,891.529297 
	C1164.791016,892.765320 1169.573486,894.357483 1174.816895,896.413269 
	C1176.860596,897.234985 1178.443359,897.593079 1180.026123,897.951233 
	C1180.888062,898.079895 1181.750122,898.208557 1182.817383,898.594604 
	C1183.051025,905.435852 1180.768799,910.605652 1174.922729,914.325134 
	C1173.118164,915.473328 1172.504395,918.509460 1171.390503,920.703857 
	C1171.262695,920.955566 1171.580688,921.522095 1171.430908,921.661194 
	C1166.275635,926.446106 1168.755127,932.116943 1169.508179,937.596863 
	C1169.533203,937.779541 1169.072998,938.028931 1168.431396,938.627441 
	C1167.282837,936.878113 1166.316406,935.077454 1165.020996,933.557007 
	C1164.245972,932.647095 1162.956177,932.175720 1161.897705,931.507263 
	C1161.488281,932.633789 1161.066772,933.755981 1160.671997,934.887573 
	C1159.653198,937.806702 1159.255493,941.187561 1157.487305,943.540771 
	C1155.029419,946.812073 1151.195801,946.300415 1148.102417,943.218018 
	C1147.352905,942.471130 1146.412964,941.915344 1146.105713,941.683411 
	C1143.402710,944.651367 1141.493530,948.556396 1138.600342,949.526367 
	C1133.488037,951.240295 1128.510132,953.449158 1123.611084,955.014465 
	C1119.111084,956.452332 1115.654175,957.232910 1112.494263,961.089355 
	C1111.263184,962.591797 1106.594360,962.327759 1104.002930,961.415344 
	C1100.266602,960.099915 1097.861206,959.559570 1095.006348,963.278076 
	C1093.150513,965.695312 1089.626831,966.968201 1086.637939,968.263977 
	C1086.044189,968.521423 1084.029053,966.771667 1083.446411,965.570557 
	C1080.389771,959.268982 1079.601440,959.081848 1073.311401,962.288635 
	C1071.685059,963.117798 1069.009033,963.299316 1067.429443,962.525146 
	C1063.775757,960.734253 1060.590576,960.010315 1056.603027,961.820129 
	C1052.288330,963.778503 1050.792114,962.454163 1051.424438,957.588867 
	C1051.883667,954.056213 1050.791748,952.879700 1047.373413,953.636658 
	C1046.407471,953.850464 1045.325806,954.124451 1044.420410,953.891602 
	C1043.081055,953.547180 1041.851807,952.774597 1040.575684,952.184265 
	C1041.265137,950.838806 1041.858643,949.430176 1042.685425,948.175171 
	C1043.086304,947.566650 1043.924561,947.246155 1044.760742,946.657227 
	C1042.373535,943.273315 1039.033691,942.011169 1036.017090,943.646667 
	C1034.056763,944.709595 1032.687378,947.870056 1032.180420,950.323669 
	C1031.669556,952.796875 1032.551758,955.557861 1032.911865,958.596313 
	C1030.831177,959.831421 1028.662354,960.662842 1026.247803,961.745972 
M1053.683350,937.744995 
	C1054.937134,937.919250 1056.191040,938.093567 1057.444824,938.267822 
	C1057.086060,937.116516 1056.727173,935.965271 1056.368286,934.813904 
	C1055.395874,935.587708 1054.423462,936.361511 1053.683350,937.744995 
M1170.183716,905.547607 
	C1170.398438,906.248291 1170.613159,906.948975 1170.827881,907.649719 
	C1171.328369,907.328430 1171.828735,907.007080 1172.329102,906.685669 
	C1171.793091,906.240295 1171.257080,905.794983 1170.183716,905.547607 
M1073.159424,938.112305 
	C1072.896118,937.653870 1072.632935,937.195496 1072.369629,936.737061 
	C1072.074097,937.020203 1071.778564,937.303345 1071.483032,937.586487 
	C1071.930054,937.864563 1072.377197,938.142578 1073.159424,938.112305 
z"
      />
      <path
        fill="#C3460C"
        opacity="1.000000"
        stroke="none"
        d="
M997.976135,1204.858643 
	C986.399597,1200.929199 974.841675,1196.943726 963.241333,1193.085815 
	C950.857178,1188.967163 938.396973,1185.075317 926.036926,1180.886719 
	C915.343689,1177.262817 904.764648,1173.303223 894.096436,1169.603394 
	C886.225891,1166.873535 878.293091,1164.323120 870.317749,1161.077881 
	C873.831299,1157.822876 877.285767,1154.981934 881.021240,1152.577393 
	C897.232910,1142.141724 913.523987,1138.172119 931.584106,1148.669434 
	C934.170227,1150.172607 936.947327,1151.346191 939.629639,1152.685181 
	C950.136230,1157.930542 960.597595,1163.268799 971.156555,1168.406494 
	C983.390015,1174.358887 995.708618,1180.136719 1008.187866,1186.262451 
	C1012.876282,1190.664551 1012.626221,1190.688965 1007.976868,1194.522583 
	C1004.301331,1197.553223 1001.285156,1201.383423 997.976135,1204.858643 
z"
      />
      <path
        fill="#AB2000"
        opacity="1.000000"
        stroke="none"
        d="
M1207.981934,1150.603271 
	C1196.361938,1146.989136 1184.906616,1142.959351 1173.430664,1138.989624 
	C1155.484741,1132.782227 1137.524780,1126.615479 1119.285400,1120.219727 
	C1121.503296,1118.045410 1123.721069,1115.243530 1126.563354,1114.276245 
	C1135.346191,1111.286987 1144.380249,1109.041748 1153.291992,1106.423340 
	C1162.109985,1103.832764 1170.954956,1101.309937 1179.667358,1098.396362 
	C1182.594604,1097.417603 1185.002686,1094.930664 1187.914185,1093.850342 
	C1191.654785,1092.462646 1195.700562,1091.921509 1199.540771,1090.768677 
	C1201.475586,1090.187866 1203.342407,1089.213623 1205.058960,1088.130493 
	C1206.240601,1087.384888 1207.111206,1086.146729 1208.454346,1085.079224 
	C1208.808838,1104.971191 1208.848145,1124.912598 1208.819702,1144.854004 
	C1208.817139,1146.641113 1208.375122,1148.427856 1207.981934,1150.603271 
z"
      />
      <path
        fill="#BC3808"
        opacity="1.000000"
        stroke="none"
        d="
M1007.990234,1185.990234 
	C995.708618,1180.136719 983.390015,1174.358887 971.156555,1168.406494 
	C960.597595,1163.268799 950.136230,1157.930542 939.629639,1152.685181 
	C936.947327,1151.346191 934.170227,1150.172607 931.584106,1148.669434 
	C913.523987,1138.172119 897.232910,1142.141724 881.021240,1152.577393 
	C877.285767,1154.981934 873.831299,1157.822876 870.102783,1160.773804 
	C868.843079,1160.767944 867.728149,1160.451294 866.123657,1159.844238 
	C865.413513,1159.362183 865.174011,1159.198364 864.915466,1159.062500 
	C864.478577,1159.018677 864.041687,1158.974976 863.304321,1158.529053 
	C864.376831,1156.391479 865.438721,1154.204468 867.180481,1153.004028 
	C872.649963,1149.234253 878.355408,1145.803589 884.009033,1142.305420 
	C887.232849,1140.310791 890.541382,1138.453491 893.797363,1136.510254 
	C897.874512,1134.077148 901.936951,1131.619385 906.005798,1129.172363 
	C911.652527,1127.390503 917.299255,1125.608521 923.736267,1123.927734 
	C932.715271,1127.869141 941.292297,1131.063965 949.001648,1135.701294 
	C960.502869,1142.619507 971.441223,1150.483643 982.527527,1158.078857 
	C992.081482,1164.624390 1001.507568,1171.356567 1010.902832,1178.350342 
	C1009.873718,1181.126465 1008.931946,1183.558350 1007.990234,1185.990234 
z"
      />
      <path
        fill="#BD3305"
        opacity="1.000000"
        stroke="none"
        d="
M1066.664795,1102.875488 
	C1065.617310,1104.087036 1064.233398,1105.138428 1062.193359,1106.688354 
	C1065.192749,1112.312988 1068.275024,1118.126099 1071.397583,1123.917725 
	C1071.945923,1124.934937 1072.944458,1125.778687 1073.256714,1126.842896 
	C1075.256348,1133.660522 1077.077637,1140.530518 1079.056885,1147.354370 
	C1079.744507,1149.725098 1080.679321,1152.027344 1081.566284,1154.335938 
	C1082.278442,1156.189819 1084.020630,1158.196167 1083.691895,1159.805298 
	C1082.378540,1166.233032 1085.927246,1171.115234 1087.787598,1176.647705 
	C1088.918579,1180.011353 1087.706543,1184.121582 1087.830811,1187.886963 
	C1087.875000,1189.227051 1088.756226,1190.539673 1089.599609,1191.920044 
	C1079.542358,1195.555054 1069.140991,1199.134888 1058.286133,1202.452148 
	C1056.891968,1199.791626 1055.951294,1197.393921 1055.217651,1194.710693 
	C1059.268311,1184.985840 1063.477661,1175.628418 1062.310303,1164.836914 
	C1063.646240,1162.779053 1065.546509,1160.889038 1065.557007,1158.988892 
	C1065.630615,1145.590820 1061.037720,1133.069214 1057.708984,1120.319946 
	C1055.953857,1113.597778 1053.742310,1106.950562 1056.017578,1099.910400 
	C1059.454468,1100.845459 1062.891479,1101.780396 1066.664795,1102.875488 
z"
      />
      <path
        fill="#B13A14"
        opacity="1.000000"
        stroke="none"
        d="
M1055.010620,1194.996094 
	C1055.951294,1197.393921 1056.891968,1199.791626 1057.916016,1202.528809 
	C1052.671631,1204.574707 1047.343994,1206.281250 1041.422363,1207.691528 
	C1036.546997,1204.015381 1032.305542,1200.582520 1027.949341,1197.301636 
	C1027.101685,1196.663208 1025.798340,1196.317139 1024.721802,1196.351929 
	C1020.905762,1196.475342 1010.930603,1205.007080 1009.768555,1208.955322 
	C1006.059998,1207.706543 1002.351379,1206.457764 998.309448,1205.033813 
	C1001.285156,1201.383423 1004.301331,1197.553223 1007.976868,1194.522583 
	C1012.626221,1190.688965 1012.876282,1190.664551 1008.187866,1186.262451 
	C1008.931946,1183.558350 1009.873718,1181.126465 1011.370239,1178.349731 
	C1025.882935,1176.557861 1037.636230,1181.499023 1048.115479,1190.335205 
	C1050.224365,1192.113525 1052.702637,1193.453857 1055.010620,1194.996094 
z"
      />
      <path
        fill="#9C1200"
        opacity="1.000000"
        stroke="none"
        d="
M841.028809,1123.465332 
	C843.696228,1122.827026 846.528748,1121.899780 848.866272,1122.607666 
	C861.435425,1126.413452 873.881531,1130.625854 886.427124,1135.095093 
	C874.120605,1139.777100 861.730835,1143.989136 849.419678,1148.419678 
	C847.398560,1149.147095 845.723450,1150.835815 843.888428,1152.080322 
	C842.893005,1150.500000 841.096375,1148.948486 841.038208,1147.334351 
	C840.757324,1139.542480 840.944397,1131.733643 841.028809,1123.465332 
z"
      />
      <path
        fill="#B82C05"
        opacity="1.000000"
        stroke="none"
        d="
M844.115112,1152.378906 
	C845.723450,1150.835815 847.398560,1149.147095 849.419678,1148.419678 
	C861.730835,1143.989136 874.120605,1139.777100 886.743286,1135.255615 
	C893.092468,1133.097534 899.182434,1131.176270 905.639099,1129.213623 
	C901.936951,1131.619385 897.874512,1134.077148 893.797363,1136.510254 
	C890.541382,1138.453491 887.232849,1140.310791 884.009033,1142.305420 
	C878.355408,1145.803589 872.649963,1149.234253 867.180481,1153.004028 
	C865.438721,1154.204468 864.376831,1156.391479 862.977417,1158.487061 
	C856.747986,1156.790527 850.544983,1154.734131 844.115112,1152.378906 
z"
      />
      <path
        fill="#A06A45"
        opacity="1.000000"
        stroke="none"
        d="
M1010.146729,1209.059326 
	C1010.930603,1205.007080 1020.905762,1196.475342 1024.721802,1196.351929 
	C1025.798340,1196.317139 1027.101685,1196.663208 1027.949341,1197.301636 
	C1032.305542,1200.582520 1036.546997,1204.015381 1041.036011,1207.670532 
	C1031.234985,1214.182373 1021.028137,1215.412354 1010.146729,1209.059326 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M1179.801025,897.696411 
	C1178.443359,897.593079 1176.860596,897.234985 1175.141357,896.601562 
	C1176.528442,896.698059 1178.052246,897.069824 1179.801025,897.696411 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M852.666992,904.020935 
	C853.002136,904.455139 853.001160,904.895813 853.000061,905.667114 
	C852.168518,905.781311 851.337036,905.565002 850.243530,905.155518 
	C850.764648,904.650635 851.547729,904.339050 852.666992,904.020935 
z"
      />
      <path
        fill="#8A0C00"
        opacity="1.000000"
        stroke="none"
        d="
M862.894287,900.661926 
	C862.414368,901.038940 861.775269,901.204163 861.046814,901.134277 
	C861.550049,900.749512 862.142578,900.599792 862.894287,900.661926 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1143.645508,1266.023560 
	C1143.444336,1266.530396 1142.966187,1267.138306 1142.199219,1267.840576 
	C1142.396484,1267.331665 1142.882568,1266.728149 1143.645508,1266.023560 
z"
      />
      <path
        fill="#8A0C00"
        opacity="1.000000"
        stroke="none"
        d="
M1185.770752,899.782959 
	C1185.435913,899.834778 1184.953979,899.649414 1184.262939,899.309937 
	C1184.577148,899.285828 1185.100464,899.415833 1185.770752,899.782959 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1133.704102,1276.012451 
	C1133.774048,1276.209351 1133.563599,1276.443848 1133.137939,1276.799194 
	C1133.118042,1276.655518 1133.285034,1276.365479 1133.704102,1276.012451 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1125.709717,1283.991211 
	C1125.739624,1284.152588 1125.513550,1284.403198 1125.094238,1284.818604 
	C1125.085327,1284.682373 1125.269531,1284.381348 1125.709717,1283.991211 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1134.726196,1275.031006 
	C1134.785034,1275.210571 1134.572876,1275.452026 1134.137329,1275.812500 
	C1134.126099,1275.679932 1134.306641,1275.400269 1134.726196,1275.031006 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1135.706055,1274.001709 
	C1135.773315,1274.192505 1135.559937,1274.438354 1135.131226,1274.815308 
	C1135.112183,1274.672729 1135.281982,1274.376099 1135.706055,1274.001709 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1136.694580,1273.017334 
	C1136.761353,1273.215088 1136.545288,1273.452026 1136.111328,1273.813232 
	C1136.093872,1273.669067 1136.266724,1273.375366 1136.694580,1273.017334 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1137.729980,1272.021484 
	C1137.790283,1272.203125 1137.578003,1272.444824 1137.142578,1272.806274 
	C1137.130249,1272.672241 1137.309570,1272.390869 1137.729980,1272.021484 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1138.708618,1270.988281 
	C1138.774414,1271.174805 1138.559937,1271.427979 1138.126831,1271.816650 
	C1138.109009,1271.676392 1138.282471,1271.377319 1138.708618,1270.988281 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1139.714844,1269.989258 
	C1139.748413,1270.163574 1139.516602,1270.406616 1139.086670,1270.806030 
	C1139.075562,1270.660889 1139.262451,1270.359375 1139.714844,1269.989258 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1140.688721,1268.964355 
	C1140.730591,1269.131592 1140.513184,1269.387329 1140.108398,1269.812744 
	C1140.090454,1269.672607 1140.259888,1269.362793 1140.688721,1268.964355 
z"
      />
      <path
        fill="#E1981B"
        opacity="1.000000"
        stroke="none"
        d="
M1141.702637,1267.983398 
	C1141.732544,1268.148926 1141.504517,1268.399048 1141.075073,1268.805664 
	C1141.064087,1268.664185 1141.254395,1268.365967 1141.702637,1267.983398 
z"
      />
      <path
        fill="#B82C05"
        opacity="1.000000"
        stroke="none"
        d="
M864.996948,1159.299072 
	C865.174011,1159.198364 865.413513,1159.362183 865.794434,1159.701416 
	C865.662659,1159.744629 865.370483,1159.640259 864.996948,1159.299072 
z"
      />
      <path
        fill="#B82C05"
        opacity="1.000000"
        stroke="none"
        d="
M1055.217651,1194.710693 
	C1052.702637,1193.453857 1050.224365,1192.113525 1048.115479,1190.335205 
	C1037.636230,1181.499023 1025.882935,1176.557861 1011.457642,1178.005493 
	C1001.507568,1171.356567 992.081482,1164.624390 982.527527,1158.078857 
	C971.441223,1150.483643 960.502869,1142.619507 949.001648,1135.701294 
	C941.292297,1131.063965 932.715271,1127.869141 924.130249,1123.903809 
	C925.015015,1123.062866 926.222595,1122.133667 927.588196,1121.663818 
	C941.796692,1116.776123 956.030579,1111.962402 970.537781,1107.410156 
	C970.061279,1110.015137 967.369507,1112.738159 971.289490,1114.415894 
	C976.650696,1116.710449 981.239624,1121.306396 987.857727,1120.178833 
	C988.867493,1120.006836 990.197510,1120.584229 991.117920,1121.203613 
	C996.809692,1125.034180 1001.680969,1126.435913 1008.783691,1122.336304 
	C1013.286560,1119.737427 1020.318909,1121.328979 1024.033813,1115.602905 
	C1029.029785,1116.382568 1029.970337,1112.854248 1030.991821,1109.227173 
	C1031.270386,1108.238281 1032.251099,1107.447021 1032.905273,1106.570923 
	C1033.662964,1106.890503 1034.195923,1106.977783 1034.538452,1107.280518 
	C1038.981201,1111.206543 1038.774780,1118.726807 1045.267822,1121.171387 
	C1045.776001,1121.362793 1045.873169,1122.990234 1045.936035,1123.964600 
	C1046.363037,1130.578247 1048.439453,1136.533936 1052.225098,1142.038818 
	C1053.660522,1144.126099 1055.011108,1146.992065 1054.796143,1149.357544 
	C1054.177856,1156.166748 1058.255615,1160.421509 1062.000000,1165.000000 
	C1063.477661,1175.628418 1059.268311,1184.985840 1055.217651,1194.710693 
z"
      />
      <path
        fill="#BE2F03"
        opacity="1.000000"
        stroke="none"
        d="
M1062.310303,1164.836914 
	C1058.255615,1160.421509 1054.177856,1156.166748 1054.796143,1149.357544 
	C1055.011108,1146.992065 1053.660522,1144.126099 1052.225098,1142.038818 
	C1048.439453,1136.533936 1046.363037,1130.578247 1045.936035,1123.964600 
	C1045.873169,1122.990234 1045.776001,1121.362793 1045.267822,1121.171387 
	C1038.774780,1118.726807 1038.981201,1111.206543 1034.538452,1107.280518 
	C1034.195923,1106.977783 1033.662964,1106.890503 1032.905273,1106.570923 
	C1032.251099,1107.447021 1031.270386,1108.238281 1030.991821,1109.227173 
	C1029.970337,1112.854248 1029.029785,1116.382568 1024.033813,1115.602905 
	C1020.318909,1121.328979 1013.286560,1119.737427 1008.783691,1122.336304 
	C1001.680969,1126.435913 996.809692,1125.034180 991.117920,1121.203613 
	C990.197510,1120.584229 988.867493,1120.006836 987.857727,1120.178833 
	C981.239624,1121.306396 976.650696,1116.710449 971.289490,1114.415894 
	C967.369507,1112.738159 970.061279,1110.015137 970.907837,1107.344727 
	C971.241699,1106.803589 971.475281,1106.597778 971.855957,1106.208130 
	C977.518250,1104.247803 983.048584,1102.534668 988.521912,1100.655151 
	C1000.461853,1096.555176 1012.372681,1092.370483 1024.653076,1088.138916 
	C1035.159912,1091.869873 1045.309082,1095.682373 1055.737915,1099.702637 
	C1053.742310,1106.950562 1055.953857,1113.597778 1057.708984,1120.319946 
	C1061.037720,1133.069214 1065.630615,1145.590820 1065.557007,1158.988892 
	C1065.546509,1160.889038 1063.646240,1162.779053 1062.310303,1164.836914 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M849.768372,923.242554 
	C849.395264,924.721741 848.960388,926.528931 848.131775,928.132568 
	C847.379211,929.589111 846.198242,930.824219 845.594177,932.892273 
	C849.859070,931.178833 854.829712,937.597412 858.395325,931.181763 
	C858.434265,931.111816 861.452454,932.065430 861.583740,932.806335 
	C861.886719,934.515991 861.268372,936.388855 861.010620,938.595459 
	C859.940491,938.250427 858.883423,937.505554 856.719116,935.980469 
	C855.862366,939.074768 854.433716,941.758972 854.596802,944.342773 
	C854.759521,946.921387 856.516785,949.399353 857.661011,952.126526 
	C850.110107,952.210205 848.334045,950.560303 847.945007,944.231567 
	C847.817627,942.161438 847.473450,939.869629 846.456726,938.130249 
	C842.261536,930.953613 842.163025,930.981445 847.941284,925.392944 
	C848.534119,924.819580 849.006653,924.121887 849.768372,923.242554 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M862.089722,942.397949 
	C863.837402,942.672852 865.669983,943.345764 867.502502,944.018616 
	C866.227234,946.615662 864.952026,949.212708 863.340820,951.904907 
	C862.728149,948.932007 862.451416,945.863953 862.089722,942.397949 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M879.275696,961.827759 
	C879.571594,960.571533 880.143188,959.147949 880.714722,957.724304 
	C881.421509,958.275940 882.128296,958.827637 882.917480,959.687256 
	C881.850464,960.550232 880.700867,961.105347 879.275696,961.827759 
z"
      />
      <path
        fill="#8A0C00"
        opacity="1.000000"
        stroke="none"
        d="
M1033.086182,959.373047 
	C1038.895630,959.175049 1043.169678,964.290161 1041.275391,969.201782 
	C1040.779785,970.486755 1039.151733,971.334961 1038.043823,972.383728 
	C1037.068115,971.212524 1035.641724,970.186218 1035.209229,968.840454 
	C1034.262695,965.895020 1033.820435,962.787476 1033.086182,959.373047 
z"
      />
      <path
        fill="#921100"
        opacity="1.000000"
        stroke="none"
        d="
M1073.828369,1051.771484 
	C1074.012085,1051.857666 1074.109863,1052.021484 1074.207520,1052.185303 
	C1074.052490,1052.073120 1073.897461,1051.961060 1073.828369,1051.771484 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M855.249329,928.023560 
	C854.631897,927.904602 854.178101,927.624695 853.724243,927.344849 
	C854.021484,927.059875 854.318665,926.774902 854.615967,926.489990 
	C854.881653,926.947571 855.147278,927.405090 855.249329,928.023560 
z"
      />
      <path
        fill="#8A0C00"
        opacity="1.000000"
        stroke="none"
        d="
M929.964478,984.284668 
	C929.892639,984.095459 929.937744,983.910889 929.982910,983.726257 
	C930.015747,983.913940 930.048645,984.101562 929.964478,984.284668 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M1053.567261,937.440186 
	C1054.423462,936.361511 1055.395874,935.587708 1056.368286,934.813965 
	C1056.727173,935.965271 1057.086060,937.116516 1057.444824,938.267822 
	C1056.191040,938.093567 1054.937134,937.919250 1053.567261,937.440186 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M1170.452393,905.448608 
	C1171.257080,905.794983 1171.793091,906.240295 1172.329102,906.685669 
	C1171.828735,907.007080 1171.328369,907.328430 1170.827881,907.649658 
	C1170.613159,906.948975 1170.398438,906.248291 1170.452393,905.448608 
z"
      />
      <path
        fill="#860F00"
        opacity="1.000000"
        stroke="none"
        d="
M1072.991821,938.266479 
	C1072.377197,938.142578 1071.930054,937.864563 1071.483032,937.586487 
	C1071.778564,937.303345 1072.074097,937.020203 1072.369629,936.737061 
	C1072.632935,937.195496 1072.896118,937.653870 1072.991821,938.266479 
z"
      />
    </svg>
  );
};

export default LogoIcon;
